import './modal.css';
import './style.css';

const ModalMintNewALf = ({ handleClose6, show6, children, user, balance }) => {
  const showHideClassName = show6 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose6()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>CLOSE</h3>
						</div>
						</div>
							<h2>Mint New TAR</h2>
							<h2>100 FUSE each</h2>
					<div className="alfContainer registerName">	
						{children}
					</div>
				</div>
			</div>	
		</div>
	);	
};

export default ModalMintNewALf;