import './modal.css';
import './style.css';

const ModalAirdrop = ({ handleClose11, show11, children, user, balance }) => {
  const showHideClassName = show11 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose11()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer">
							<h3>CLOSE</h3>
						</div>
						</div>

							<h2>Airdrop</h2>
					<div className="alfContainer registerName">	
						{children}
					</div>
				</div>
			</div>	
		</div>
	);	
};

export default ModalAirdrop;