import './modal.css';
import './style.css';

const ModalAdminRace = ({ handleClose2, show2, children, race }, props) => {
  const showHideClassName = show2 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer shortUser">
					<div onClick= {() => {handleClose2()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">	
							<h4>CLOSE</h4>
						</div>
					</div>
						<h2>Race Admin View. Race { race }</h2>
					<div className="alfContainer">			
						{children}
						{props[0]}
					</div>	
					<p>At end of race, Post Results first then End Race last</p>
				</div>
			</div>	
		</div>
	);	
};

export default ModalAdminRace;