import './modal.css';
import './style.css';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const ModalFrontPage = ({ handleClose10, show10, children, user, network }) => {
  const showHideClassName = show10 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);
 const ABINetwork = window.Configs.NETWORK_ID;
 let enterButton;
 let connectButton;
if(network == ABINetwork){
	enterButton = <div className="frontPageButton frontPage" onClick= {() => {handleClose10(); }}style={{cursor:'pointer'}} ><h3 style={{color: "green"}}>Enter</h3></div>
	connectButton = "";
}else {
	enterButton = "";
	connectButton = <div className="frontPageButton frontPage"  onClick={() => {window.location.reload(false);} } ><h3 style={{color: "yellow"}}>Connect Wallet</h3></div>
}
	return (
		<div className={showHideClassName}>
			<div className="headerContainer">	
				<div className="frontMain">
					<div className="frontContainer">
					
						{enterButton}

						{connectButton}
						
					</div>
				</div>
			</div>	
		</div>
	);	
};
export const FrontCover = styled(Box)`
background-size : 100% 100% ;
background-color: black;
 width: 1500px;
`

export const FrontContainer = styled(Box)`
background : url('images/1873.jpg') ;
 background-size : 100% 100% ;
 height:950px;
 width: 700px;
 align-items: center;
 	display : flex ;
	flex-direction : column ;
	margin: auto;
`
export default ModalFrontPage;