import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css';

ReactDOM.render(

    <App />,

  document.getElementById('root')
);

