import './modal.css';
import './style.css';

const ModalRegisterName = ({ handleClose5, show5, children, user }) => {
  const showHideClassName = show5 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose5()}}style={{cursor:'pointer'}} >
							<div className="buttonContainer closeButton">
								<h3>CLOSE</h3>
							</div>
						</div>
					
							<h2>NameServer</h2>
						
							<h2>Register/Change name</h2>
						
						
					<div className="alfContainer registerName">			
						{children}
					</div>	
				</div>
			</div>	
		</div>
	);	
};

export default ModalRegisterName;