import './modal.css';
import './style.css';
import './gemStyle.css';

const ModalGems = ({ handleClose12, show12, children, user, balance, gemBalance }) => {
  const showHideClassName = show12 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainerGems">		
				<div className="userContainer userGemContainer">
					<div onClick= {() => {handleClose12()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>CLOSE</h3>
						</div>
					</div>
					<h2>MY GEMS: {gemBalance}</h2>
					<div className=" ">	
						{children}
					</div>
				</div>
			</div>	
		</div>
	);	
};

export default ModalGems;