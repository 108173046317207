import './modal.css';
import './style.css';

const ModalHelm = ({ handleClose3, show3, id, name, children }, props) => {
  const showHideClassName = show3 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
//  console.log("props " + JSON.stringify(props));

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer modalHelmContainer">
					<div onClick= {() => {handleClose3()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>CLOSE</h3>
						</div>
					</div>
					<h2>HELM</h2>
					<div className="inline">	
						<h3> Name: {name}</h3>
						<h3> Id: {id}</h3>
					</div>
					{children}
					{props[0]}
				</div>
			</div>	
		</div>
	);	
};

export default ModalHelm;