import './modal.css';
import './style.css';

const ModalUserRace = ({ handleClose8, show8, children, race, ready }, props) => {
  const showHideClassName = show8 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">	
				<div className="userContainer">
					<div onClick= {() => {handleClose8()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>CLOSE</h3>
						</div>
					</div>
						<h2>Race { race } User View</h2>
						<h2> Next race is ready:  {ready.toString()}</h2>
					<div className="alfContainer">			
						{children}
						{props[0]}
					</div>	
				</div>
			</div>	
		</div>
	);	
};

export default ModalUserRace;