import { Box } from '@material-ui/core';
import styled from 'styled-components';


	export const StyledContainer = styled(Box)`
    background : url('images/1873.jpg') ;
   background-size : 100% 100% ;
    min-height : 100vh ;
    display : flex ;
    flex-direction : column ;
	font-family: Arial;
	
`

export const HeaderContainer = styled(Box)`
    display : flex ;
    justify-content : center ;
    margin-top : 0px ;
`

 export const UserContainer = styled(Box)`
    background-color: #2e293c;
    border: 2px solid #c6b76d;
    border-radius: 35px;
    display : flex ;
    flex-direction : column ;
    width : 100% ;
    max-width : 1200px ;
    padding : 10px 10px ;
    height : 100% ;
   align-items: center;
	color: #c6b76d;
`
 export const TitleContainer = styled(Box)`
    background-color: #660099;
    border: 2px solid #c6b76d;
    border-radius: 35px;
    display : flex ;
    flex-direction : column ;
    width : 100% ;
    max-width : 1200px ;
    padding : 5px ;
    min-height : 190px ;
	height: 100%;
    justify-content : center ;
	margin-top: 10px;

`

  export const MusicBox = styled (Box)`
  	border-radius: 35px;
	border: 2px solid #c6b76d;
	width: 100%;
	 padding: 5px;
	background-color: #2e293c;
	flex-direction : column ;
	margin: 5px;
  `
  
  export const BiggerBox = styled (MusicBox)`
    max-width : 900px ;

 `
 
 export const SmallBox = styled (MusicBox)`
 width:230px;
 `
 export const BodyContainer = styled(TitleContainer)`
    background-color: #664499;
	margin-top: 10px;
	height: 120px;
`
 export const AccountBodyContainer = styled(TitleContainer)`
    background-color: #664499;
	margin-top: 10px;
	height: 60px;
`
 export const AlfContainer = styled(BodyContainer)`
    height : 500px ;
	color: #c6b76d;
`
 export const AlfBorder = styled(UserContainer)`
 height : 540px ;
 margin-bottom: 50px;
 `
 
 export const Card = styled(Box)`
 height: 340px;
 width:160px;
 background-color: #dddddd;
 color: #1111bb;
 padding:10px;
 cursor: pointer;
 `
 



 
 export const MintContainer = styled(UserContainer)`
	height: 210px;
 `
 
 export const FieldContainer = styled (UserContainer)`
	padding : 10px  ;
	height: 530px;
	width:500px;
	width: 100%;
 `
  export const UserFieldContainer = styled (UserContainer)`
	padding : 10px  ;
	height: 530px;
	width:250px;
 `
 export const RaceContainer = styled (UserContainer)`
	padding : 10px;
	padding-bottom: 30px;
	height: 530px;
	width: 250px;
	width: 100%;
 `
  export const RacePrestige = styled (UserContainer)`
	padding : 30px 10px;

	height: 230px;
	width: 200px;
	background-color: #660099;
 `
   export const RaceEntrants = styled (UserContainer)`
	padding : 0px 5px;
	border-radius: 10px;
	border: 1px solid #c6b76d;
	height: 40px;
	width: 200px;
	background-color: #337733;
	margin-top:1px;
 `
    export const RaceFinishers = styled (RaceEntrants)`
	padding : 0px 5px;
	border-radius: 10px;
	border: 1px solid #c6b76d;
	height: 40px;
	width: 200px;
	background-color: #3333ff;
	margin-top:1px;
 `

 
 export const TrophyContainer = styled(UserContainer)`
 height: 100px;
 width: 100%;
 margin-top: 15px;
 margin-bottom: 15px;
 `
 
 export const LeaderBoardContainer = styled (UserContainer)`
 width: 100%;
 height: 500px;
 padding-left: 20px;
 `
 
 export const WinnerAlfContainer = styled(AlfContainer)`
 padding: 1px;
 padding-bottom: 10px;
 height: 400px;
  border-radius: 15px;
  color: #000000;
 `
 
 export const PlotContainer = styled(UserContainer)`
 	padding : 10px;
	display: flex;
	flex-direction : column ;
	height: 400px;
	width: 250px;
	margin-top: 20px;
	align-items: center;
 `
 export const PlotContainer1 = styled(Box)`
     background-color: #2e293c;
    border: 2px solid #c6b76d;
    border-radius: 35px;
    width : 100% ;
    padding : 20px ;
    height : 420px ;  
	color: #c6b76d;
	margin-top: 20px;
	margin-left: 1px;
 `
 
  export const SquatContainer = styled(UserContainer)`
 	padding : 10px;
	display: flex;
	height: 200px;
	width: 100%;
	margin-top: 20px;
	margin-left: 1px;
 `
 
 export const ChooseLegContainer = styled(UserContainer)`
 	padding : 10px;
	height: 640px;
	width: 600px;
	margin-top: 20px;
	margin-left: 10px;
	padding-left: 20px;
	padding-bottom: 20px;
	
 `
 
 export const FinalFuel = styled(Box)`
     background-color: #664499;
    border: 2px solid #c6b76d;
    border-radius: 25px;
    display : flex ;
    flex-direction : column ;
    width : 100% ;
    max-width : 250px ;
    padding : 4px  ;
    height : 170px ;
	color: #5588ff;
	align-items: center;
	margin: auto;
 `
 
 export const ButtonContainer = styled(Box)`
  background-color: #2e293c;
    border: 2px solid #c6b76d;
    border-radius: 10px;
    width : 100% ;
    width : 130px ;
    padding : 2px;
    height : 30px ;
	align-items: center;
	color: #c6b76d;
	margin: auto;
 `
  export const MintButtonContainer = styled(ButtonContainer)`
  background-color: #660099;
 `
  export const PlotButton = styled(ButtonContainer)`
  background-color: #000;
    border: 1px solid #c6b76d;
    width : 60px ;
    padding : 1px 5px ;
    height : 22px ;
	margin-top: 5px;
 `
  export const PlotEngineButton = styled(ButtonContainer)`
  background-color: #000;
    border: 1px solid #c6b76d;
    width : 100px ;
    padding : 1px 5px ;
    height : 25px ;
	margin-top: 2px;
 ` 
 export const WhatsThisButton = styled(PlotEngineButton)`
 background-color: #00f;
 cursor: pointer;
 `
  export const SubmitFuelButton = styled(PlotEngineButton)`
 background-color: #f70;
 cursor: pointer;
 color: black;
 `
   export const LayInCourseButton = styled(PlotEngineButton)`
 background-color: #333;
 cursor: pointer;
 color: #ddd;
 `
    export const LockCourseButton = styled(PlotEngineButton)`
 background-color: #2d2;
 cursor: pointer;
 color: black;
 `
 export const DotButtonRed = styled(Box)`
  background-color: #ff2222;
    border: 2px solid #c6b76d;
    border-radius: 10px;
    width : 10% ;
    width : 20px ;
	height : 20px ;
	align-items: center;
	color: #c6b76d;
	margin: 5px;
 `
  export const DotButtonGreen = styled(Box)`
  background-color: #22ff22;
    border: 2px solid #c6b76d;
    border-radius: 10px;
    width : 10% ;
    width : 20px ;
	height : 20px ;
	align-items: center;
	color: #c6b76d;
	margin: 5px;
 `
   export const DotButton = styled(Box)`
  background-color: #2e293c;
    border: 2px solid #c6b76d;
    border-radius: 10px;
    width : 10% ;
    width : 20px ;
	height : 20px ;
	align-items: center;
	color: #c6b76d;
	margin: 5px;
 `
 export const ButtonRow = styled(Box)`
	margin-top: 5px;
     display : flex ;
    flex-direction : row ;
 `



 export const ButtonContainerAnim = styled(ButtonContainer)`
  background : url('images/starsSlow.gif') ;
background-size : 100% 100% ;
width: 180px;
height: 60px;
margin-top: 10px;
padding-top: 10px;
 `

