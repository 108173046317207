import { Button} from 'semantic-ui-react';
import './modal.css';
import './style.css';
import './gemStyle.css';

const ModalGemRoundValues = ({ handleClose13, show13, children, user, balance }) => {
  const showHideClassName = show13 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose13()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>BACK</h3>
						</div>
					</div>

					<h2>GEM ROUND COLLECTION VALUES</h2>
					<div className="alfContainer gemContainer">	
						<div>
							<div className="inlineDesktop">
								<div className="inline">
								
										<div>
											<h3>Round Gems</h3>
											<div className="roundGems">
											</div>
										</div>
										<div className="gemValues">
											<h4>value</h4>
											<h3>2</h3>							
											<h3>4</h3>						
											<h3>4</h3>						
											<h3>8</h3>							
											<h3>8</h3>						
											<h3>16</h3>							
											<h3>16</h3>						
											<h3>32</h3>							
											<h3>32</h3>
											<h3>64</h3>
										</div>
								</div>
								<div className="textValuesGem">
									Round Gems
									<h4>2 x green = 1 tealGem</h4>
									<h4>3 x green = 1 blueGem or greenDrop</h4>
									<h4>2 x teal = 1 blueGem or greenDrop</h4>
									<h4>3 x teal = 1  purpleGem or tealDrop or greenHeart</h4>
									<h4>2 x blue  = 1 purpleGem or tealDrop or greenHeart</h4>						
									<h4>3 x blue = 1 redGem or blueDrop or tealHeart</h4>							
									<h4>2 x purple  = 1 redGem or  blueDrop or tealHeart</h4>							
									<h4>3 x purple = 1 purpleDrop or  blueHeart</h4>							
									<h4>2 x red = 1 purpleDrop or  blueHeart</h4>							
									<h4>3 x red = 1 redDrop or  purpleHeart</h4>
								</div>
							</div>
	
						</div>	
					</div>
				</div>
			</div>	
		</div>
	);	
};

export default ModalGemRoundValues;