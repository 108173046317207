import { Button} from 'semantic-ui-react';
import './modal.css';
import './style.css';
import './gemStyle.css';

const ModalAllGemValues = ({ handleClose18, show18, children, user, balance }) => {
  const showHideClassName = show18 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);


	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose18()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>BACK</h3>
						</div>
					</div>

					<h2>All GEM COLLECTION VALUES</h2>
					<div className="alfContainer gemContainer">	
					
					

		<div className="inline ">
			<div className="allCards">

				<div className="inline">				
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenGem"}); this.addMyGem("greenGem")}}>
						<h3> Gem </h3>
						<h4> 1 point </h4>						
						<div className="greenGem" >
						</div>						
					</div>	
					<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealGem"}); this.addMyGem("tealGem")}}>	
						<h3> Gem </h3>
						<h4>  2 points </h4>						
						<div className="tealGem" >
						</div>
					</div>			
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueGem"}); this.addMyGem("blueGem")}}>
						<h3> Gem  </h3>
						<h4>  4 points </h4>						
						<div className="blueGem" >	
						</div>	
					</div>
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleGem"}); this.addMyGem("purpleGem")}}>	
						<h3> Gem  </h3>
						<h4>  8 points</h4>						
						<div className="purpleGem" >
						</div>
					</div>					
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redGem"}); this.addMyGem("redGem")}}>	
						<h3> Gem  </h3>
						<h4>  16 points</h4>						
						<div className="redGem" >	
						</div>
					</div>				
				</div>
				<div className="inline">
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenTear"}); this.addMyGem("greenTear")}}>
						<h4> Teardrop </h4>
						<h4> 4 points</h4>						
						<div className="greenTear" >
						</div>
					</div>	
						<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealTear"}); this.addMyGem("tealTear")}}>	
						<h3> Teardrop  </h3>
						<h4>  8 points </h4>						
						<div className="tealTear" >
						</div>
					</div>		
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueTear"}); this.addMyGem("blueTear")}}>
						<h3> Teardrop  </h3>
						<h4>  16 points </h4>						
						<div className="blueTear" >	
						</div>	
					</div>	
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleTear"}); this.addMyGem("purpleTear")}}>	
						<h3> Teardrop  </h3>
						<h4>32 points </h4>						
						<div className="purpleTear" >
						</div>
					</div>				
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redTear"}); this.addMyGem("redTear")}}>	
						<h3> Teardrop  </h3>
						<h4>  64 points </h4>
						<div className="redTear" >	
						</div>
					</div>				
				</div>	
				<div className="inline">
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenHeart"}); this.addMyGem("greenHeart")}}>
						<h3> Heart </h3>
						<h4> 8 points </h4>						
						<div className="greenHeart" >
						</div>
					</div>	
						<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealHeart"}); this.addMyGem("tealHeart")}}>	
						<h3> Heart  </h3>
						<h4>  16 points </h4>						
						<div className="tealHeart" >
						</div>
					</div>			
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueHeart"}); this.addMyGem("blueHeart")}}>
						<h3> Heart  </h3>
						<h4>  32 points</h4>						
						<div className="blueHeart" >	
						</div>	
					</div>	
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleHeart"}); this.addMyGem("purpleHeart")}}>	
						<h3> Heart  </h3>
						<h4>  64 points</h4>						
						<div className="purpleHeart" >
						</div>
					</div>					
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redHeart"}); this.addMyGem("redHeart")}}>	
						<h3> Heart  </h3>
						<h4> 128 points </h4>						
						<div className="redHeart" >	
						</div>
					</div>				
				</div>
				<div className="inline">
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenDiamond"}); this.addMyGem("greenDiamond")}}>
						<h3> Diamond </h3>
						<h4> 16 points </h4>	
						<div className="greenDiamond" >
						</div>
					</div>	
						<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealDiamond"}); this.addMyGem("tealDiamond")}}>	
						<h3> Diamond  </h3>
						<h4>  32 points </h4>					
						<div className="tealDiamond" >
						</div>
					</div>			
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueDiamond"}); this.addMyGem("blueDiamond")}}>
						<h3> Diamond  </h3>
						<h4>  64 points</h4>					
						<div className="blueDiamond" >	
						</div>	
					</div>	
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleDiamond"}); this.addMyGem("purpleDiamond")}}>	
						<h3> Diamond  </h3>
						<h4>  128 points</h4>					
						<div className="purpleDiamond" >
						</div>
					</div>					
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redDiamond"}); this.addMyGem("redDiamond")}}>	
						<h3> Diamond  </h3>
						<h4> 256 points </h4>					
						<div className="redDiamond" >	
						</div>
					</div>				
				</div>			
			</div>
			
	
		</div>	

						
					
					
					</div>	
				</div>
			</div>	
		</div>
	);	
};

export default ModalAllGemValues;