import './modal.css';

const Modal = ({ handleClose, show, children, id, name }, props) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
//  const myData = props.myData;

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">	
				<div className="userContainer modalUser">
					<div onClick= {() => {handleClose()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">	
							<h3>CLOSE</h3>
						</div>
					</div>							
					{children}
					{props[0]}	
				</div>
			</div>				
		</div>
	);	
};

export default Modal;