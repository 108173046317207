import React, { Component } from "react";
import Web3 from 'web3';
import { Segment, Button, Form, Icon, Popup} from 'semantic-ui-react';
import { Grid } from '@material-ui/core';
import Modal from './components/Modal';
import ModalAdminRace from './components/ModalAdminRace';
import ModalUserRace from './components/ModalUserRace';
import ModalHelm from './components/ModalHelm';
import ModalPlot from './components/ModalPlot';
import ModalRegisterName from './components/ModalRegisterName';
import ModalMintNewAlf from './components/ModalMintNewAlf';
import ModalLeaderboard from './components/ModalLeaderboard';
import ModalWhatsThis from './components/ModalWhatsThis';
import ModalFrontPage from './components/ModalFrontPage';
import ModalAirdrop from './components/ModalAirdrop';
import ModalGems from './components/ModalGems';
import ModalAllGemValues from './components/ModalAllGemValues';
import ModalGemRoundValues from './components/ModalGemRoundValues';
import ModalGemDropValues from './components/ModalGemDropValues';
import ModalGemHeartValues from './components/ModalGemHeartValues';
import ModalGemDiamondValues from './components/ModalGemDiamondValues';
import ModalWinner from './components/ModalWinner';
//import window.Configs from "../window.Configs.json";
import { Howl, Howler } from "howler";
import './App.css';
import * as styles from './styles'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const CONTRACT_ADDRESS = window.Configs.CONTRACT_ADDRESS;
const GEM_TRADING_ADDRESS = window.Configs.GEM_TRADING_ADDRESS;
const TOKEN_CONTRACT_ADDRESS = window.Configs.TOKEN_CONTRACT_ADDRESS;

const ABI = window.Configs.ABI;
const GEMTRADEABI = window.Configs.GEMTRADEABI;
const TOKENABI = window.Configs.TOKENABI;

class App extends Component {
	
	constructor(props) {
		super(props)
		this.state = {
			account: '0x0000000000000000000000000000000000000000',
			balance: 0,
			gemBalance: [],
			total: 0,
			myNameCheck: "",
			namesCount: 0,
			allNames: [],
			nameApproved: false,
	//		myUserName: "",
			registeredUserHandle: "",
			truncatedAcct: "",
			alienNow: 0,
			aliens: [],
			myAliensArray: [],
			globalAliensArray: [],
			id: 0, 
			hasStarted: false,
			hasEnded: false,
			distance: 0,
			newDistance: 0,
			raceNumber: 1,
			startTime: 0,
			level: 0,			
			races: 0,
			wins: 0,
			prestige: 0,
			userPrestige: 0,
			engine: 1,
			enginesAdded: 0,
			fieldAdded: 0,
//			engines: [],
	//		mySpeed: 1,
			formattedStartTime: '',
			speed: 0,
			timestamp: 0,
			myTime: 0,
			screenOn: true,
			fieldConditions: 0,
			fuelAllowance: 0,
			newFuelAllowance: 0,
			setRaceType: "Standard",
			setEntryFee: 0,
			setPrize: 0,
			setCooldown: 22,
			setPrestige: 2,
	//		fieldConditionsArray: [],
			isAllowedToEnter: false,
			stringMyField: [],
			field1: 0,
			field2: 0,
			field3: 0,
			field4: 0,
			field5: 0,
			field6: 0,
			field7: 0,
			field8: 0,
			field9: 0,	
			finalField: 0,
			leg1: 0,
			leg2: 0,
			leg3: 0,
			leg4: 0,
			leg5: 0,
			leg6: 0,
			leg7: 0,
			leg8: 0,
			leg9: 0,
			finalSprint: 0,
			sprintTime: 0,
			totalFuelUsed: 0,
			fuelLeft: 0,
			finalFuelBool: false,
			finalFuel: 0,
			fuel: 0,
			thisLeg: "?",
			thisField: "?",
			myConditions: [],
			savedConditions: [],
			stringMyConditions: [],
			myStrategy: [],
			stringMyStrategy: [],
			savedStrategy: [], 
			fuelStrategy: 0,
			eArray: [],
			fArray: [],
			racers: [],
			racersName: [],
			finishers: [],
			finishersName: [],
			finishersTime: [],
			racerReward: [],
			winnerTime: 0,
			winnerIndex: 0,
			winnerName: "",
			submitted: false,
			leaderboard: [],
			strategy: 111111111, 
			winners: [],
			myWinnersArray: [], 
			allAliensArray: [],
			totalTimeTaken: 0,
			networkErrorMessage: window.Configs.NETWORK_ERROR_MESSAGE,
			contractIndicator: "",
			audioClass: "audioStop",
			raceReady: false,
			Open: false,
			Open1: false,
			text: "Please connect Metamask to " + window.Configs.NETWORK_NAME + " Network.",
			gemClicks: 0,
			gemTradeItems: [],
			thisGemTrade: "",
			gemSelected: false,
			has3Gems: false,
			currentApproval: false,
			winnersPosted: false,
			racerRewardArray: [],
			finisherOwners: [],
			achieversLength: 0,
			achievements: [],
			myAchieversArray: [],
			myAchieversArrayRename: [],
			myAchieversArrayNFT: [],
			myAchieversArrayPres: [],
			myAchieversArrayTemp: [],
			gemOwnersArray: [],
			nftWinnersArray: [],
			winnerOwner: [],
			gemAchieversArray: [],
			winAchieversArray: [],
			winAliensArray: [],
			allOwnersArray: [],
			gemOwnersLength: [],
			nameServer: [],
			gotName: "",
			OptionalThirdGem: 0
		};
		
		
		this.showModal = this.showModal.bind(this);
		this.hideModal = this.hideModal.bind(this);	
		this.showModalAdminRace = this.showModalAdminRace.bind(this);
		this.hideModalAdminRace = this.hideModalAdminRace.bind(this);	
		this.showModalUserRace = this.showModalUserRace.bind(this);
		this.hideModalUserRace = this.hideModalUserRace.bind(this);			
		this.showModalHelm = this.showModalHelm.bind(this);
		this.hideModalHelm = this.hideModalHelm.bind(this);	
		this.showModalPlot = this.showModalPlot.bind(this);
		this.hideModalPlot = this.hideModalPlot.bind(this);		
		this.showModalRegisterName = this.showModalRegisterName.bind(this);
		this.hideModalRegisterName = this.hideModalRegisterName.bind(this);	
		this.showModalNewAlf = this.showModalNewAlf.bind(this);
		this.hideModalNewAlf = this.hideModalNewAlf.bind(this);	
		this.showModalLeaderboard = this.showModalLeaderboard.bind(this);
		this.hideModalLeaderboard = this.hideModalLeaderboard.bind(this);	
		this.showModalWhatsThis = this.showModalWhatsThis.bind(this);
		this.hideModalWhatsThis = this.hideModalWhatsThis.bind(this);
		this.showModalFrontPage = this.showModalFrontPage.bind(this);
		this.hideModalFrontPage = this.hideModalFrontPage.bind(this);	
		this.showModalAirdrop = this.showModalAirdrop.bind(this);
		this.hideModalAirdrop = this.hideModalAirdrop.bind(this);
		this.showModalGems = this.showModalGems.bind(this);
		this.hideModalGems = this.hideModalGems.bind(this);
		this.showModalAllGemValues = this.showModalAllGemValues.bind(this);
		this.hideModalAllGemValues = this.hideModalAllGemValues.bind(this);		
		this.showModalGemRoundValues = this.showModalGemRoundValues.bind(this);
		this.hideModalGemRoundValues = this.hideModalGemRoundValues.bind(this);
		this.showModalGemDropValues = this.showModalGemDropValues.bind(this);
		this.hideModalGemDropValues = this.hideModalGemDropValues.bind(this);	
		this.showModalGemHeartValues = this.showModalGemHeartValues.bind(this);
		this.hideModalGemHeartValues = this.hideModalGemHeartValues.bind(this);
		this.showModalGemDiamondValues = this.showModalGemDiamondValues.bind(this);
		this.hideModalGemDiamondValues = this.hideModalGemDiamondValues.bind(this);	
		this.showModalWinner = this.showModalWinner.bind(this);
		this.hideModalWinner = this.hideModalWinner.bind(this)		
	}

	
	showModal = (name, id, finalFuel, races, finishes, wins, myCooldown, myRaceNumber, strategy, recentTime, isRacing, hasSubmitted) => {		
		this.setState({ show: true, name: name, id: id, level: this.state.level, finalFuel: finalFuel, races: races, finishes: finishes, myRaceNumber: myRaceNumber, raceNumber: this.state.raceNumber, wins: wins, myCooldown: myCooldown, strategy: strategy, recentTime: recentTime, isRacing: isRacing, submitted: hasSubmitted});
		this.setState({ name: name });
//		console.log("yep we clicked modal " + name);
//		console.log("yep we clicked modal id " + this.state.name);
	this.decideIfCanEnter(wins, races, myCooldown);

	};

	hideModal = () => {
		this.setState({ show: false });
//		console.log("race number = " + this.state.raceNumber);
	};	
	showModalAdminRace = (networkId) => {
		if(networkId == window.Configs.NETWORK_ID){
			this.setState({ show2: true});
		} else {
			//alert(window.Configs.NETWORK_ERROR_MESSAGE);
			this.setState({ Open: true });
		}
//		console.log("Owners " + window.location);
	};
	hideModalAdminRace = () => {
		this.setState({ show2: false });
	};	
	showModalUserRace = (networkId) => {
		if(networkId == window.Configs.NETWORK_ID){
			this.setState({ show8: true});
		} else {
			//alert(window.Configs.NETWORK_ERROR_MESSAGE);
			this.setState({ Open: true });
		}
//		console.log("users " + window.location);
	};
	hideModalUserRace = () => {
		this.setState({ show8: false });
	};	
	showModalHelm = (id, name, level, fuel) => {
		this.setState({ show3: true, id: id, name: name, level: level, fuel: fuel, strategy: this.state.savedStrategy});

	};
	hideModalHelm = () => {
		this.setState({ show3: false });
	};	
	showModalPlot = (id, name, level, fuel) => {
		this.setState({ show4: true, id: id, name: name, level: level, fuel: fuel});
		this.setState({ savedStrategy: ["","","","","","","","",""]});
		this.loadPartBlockchain();
	};
	hideModalPlot = () => {
		this.setState({ show4: false });
	};		
	showModalRegisterName = (account, networkId) => {
//		console.log(account + " " + networkId + " " + window.Configs.NETWORK_ID);
		if(networkId == window.Configs.NETWORK_ID){
			this.setState({ show5: true, account: account});
		} else {
		//	alert(window.Configs.NETWORK_ERROR_MESSAGE);
			this.setState({ Open: true });
		}
	};
	hideModalRegisterName = () => {
		this.setState({ show5: false, nameApproved: false });
	};		
	showModalNewAlf = (account, balance, networkId) => {
		
		if(networkId == window.Configs.NETWORK_ID){
		this.setState({ show6: true, account: account, balance: balance});
		} else {
		//	alert(window.Configs.NETWORK_ERROR_MESSAGE);
			this.setState({ Open: true });
		}
	};
	hideModalNewAlf = () => {
		this.setState({ show6: false});
		this.setState({ mintQty  : 0});
		this.setState({ alienName: ""});
	};		
	showModalLeaderboard = (account, networkId) => {
	//	if(networkId == window.Configs.NETWORK_ID){
		this.setState({ show7: true, account: account});
//		} else {
		//	alert(window.Configs.NETWORK_ERROR_MESSAGE);
//			this.setState({ Open: true });
//		}
	};
	hideModalLeaderboard = () => {
		this.setState({ show7: false});
	};	
	
	showModalWhatsThis = (account, networkId) => {
		this.setState({ show9: true});
	};
	hideModalWhatsThis = () => {
		this.setState({ show9: false});
	}; 

	showModalFrontPage = (sound, networkId) => {
		this.setState({ show10: true});
		this.setState({ sound: this.state.sound1 });
		this.setState({network: networkId });
	};
	hideModalFrontPage = () => {
		if(this.state.networkId == window.Configs.NETWORK_ID){
		this.setState({ show10: false});
		this.trackChangePlay();
		} else {
			this.setState({ Open: true });
			console.log("Wrong network = " +  this.state.networkId); 
		}
	};
	
	showModalAirdrop = () => {
		this.setState({ show11: true});
		console.log("airdropping");
	};
	hideModalAirdrop = () => {
		this.setState({ show11: false});
	};

	showModalGems = () => {
		this.setState({ show12: true});
		console.log("showing gems");
	};
	hideModalGems = () => {
		this.setState({ show12: false});
	}; 
 
	showModalAllGemValues = () => {
		this.setState({ show18: true});
		console.log("showing All gems");
	};
	hideModalAllGemValues = () => {
		this.setState({ show18: false});
	}; 
	showModalGemRoundValues = () => {
		this.setState({ show13: true});
		console.log("showing gems");
	};
	hideModalGemRoundValues = () => {
		this.setState({ show13: false});
	};
	showModalGemDropValues = () => {
		this.setState({ show14: true});
		console.log("showing gems");
	};
	hideModalGemDropValues = () => {
		this.setState({ show14: false});
	};
	
	showModalGemHeartValues = () => {
		this.setState({ show15: true});
		console.log("showing gems");
	};
	hideModalGemHeartValues = () => {
		this.setState({ show15: false});
	};
	showModalGemDiamondValues = () => {
		this.setState({ show16: true});
		console.log("showing gems");
	};
	hideModalGemDiamondValues = () => {
		this.setState({ show16: false});
	};
	showModalWinner = (name, id, races, finishes, wins, myCooldown, recentTime,) => {		
		this.setState({ show17: true, name: name, id: id, level: this.state.level, races: races, finishes: finishes,  wins: wins, myCooldown: myCooldown, recentTime: recentTime});
		this.setState({ name: name });
	};
	hideModalWinner = () => {
		this.setState({ show17: false });
		this.setState({gotName: "" });
	};		
		
		
	async componentDidMount(){
		this.loadBlockchainData();
		
	
this.setState({counter: 3600});
		
		this.state.sound1 = new Howl({
			src: ["https://tar.lets3d.com//audio/sb_terminus.mp3"],
			html5: true,
			onplay: function() {
//				console.log("Function playing");				
			},
			onend: function(){
//				console.log("Function ending");	
			
			}
	  });

		this.setState({ track: 1 });
		this.setState({ trackText: "Terminus" });
		
	  	this.state.sound2 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_themiracleofflight.mp3"],
			html5: true,			
		});
		
		this.state.sound3 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_catalyst.mp3"],
			html5: true,			
		});
		this.state.sound4 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_clarion.mp3"],
			html5: true,			
		});
		this.state.sound5 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_helios.mp3"],
			
			html5: true,			
		});
		this.state.sound6 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_pathfinder.mp3"],
			html5: true,			
		});
		this.state.sound7 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_signaltonoise.mp3"],
			html5: true,			
		});
		this.state.sound8 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_solace.mp3"],
			html5: true,			
		});
		this.state.sound9 = new Howl({
			src: ["https://tar.lets3d.com/audio/sb_wayfarer.mp3"],
			html5: true,			
		});
	}
	//	startTrack2( ){
	//		this.setState({ track: 2 });
	//		this.setState({ trackText: "the other one" });
	//		this.state.sound2.play();
	//	}
	
	async loadGems(){
		const gemBalance = await this.state.gemTradeContract.methods.balanceOfBatch([this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account,this.state.account], [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]).call();
		this.setState({ gemBalance });
	}

async loadPartBlockchain(){
		window.web3 = new Web3(window.ethereum)
	//	window.ethereum.enable()
		window.ethereum.request({
          method: "eth_requestAccounts",
        });
		const currentApproval = await this.state.tokenContract.methods.isApprovedForAll(this.state.account, GEM_TRADING_ADDRESS).call();
		this.setState({ currentApproval });
		const gemsClaimable = await this.state.gemTradeContract.methods.recipients(this.state.account).call();
		this.setState({ gemsClaimable });
		this.resetChoices();
		const myUserName = await this.state.alfContract.methods.nameServer(this.state.account).call();  // grabbing my usersname
		let registeredUserHandle;
		if(myUserName != 0){
			registeredUserHandle = myUserName;
			this.setState({ registeredUserHandle });
		} else {
			registeredUserHandle = this.state.account.slice(0, -34) + "...";
			this.setState({ registeredUserHandle });
		}
		const myTotal = await this.state.alfContract.methods.balanceOf(this.state.account).call();
		this.setState({ myAliensArray: [] });
		const myAliens = await this.state.alfContract.methods.walletOfOwner(this.state.account).call()	
			for(var h = 0; h < myTotal; h++){    // all my aliens into array myAliensArray[]
				const alienNow = await this.state.alfContract.methods.aliens(myAliens[h] ).call()
				// load myAliensArray with all my aliens
				this.setState(previousState => ({
					myAliensArray: [...previousState.myAliensArray, alienNow]
				}));
			}	
			
		const balance = await this.state.alfContract.methods.balanceOf(this.state.account).call()
		this.setState({ balance });
		
					
		for (var v = 0; v < this.state.achieversLength; v++){  //all achievers into array myAchieversArrayTemp
			var achiever = await this.state.alfContract.methods.achievers(v).call()
			this.setState(previousState1 => ({
				myAchieversArrayTemp: [...previousState1.myAchieversArrayTemp, achiever]	
			}));		
		}
		for(var i = 0; i < this.state.achieversLength ; i++){
			if(this.state.myAchieversArrayTemp[i][6] == this.state.account){
				console.log("got some nfts " + this.state.myAchieversArrayTemp[i][1])	
				var tempPres = Number.parseInt(this.state.myAchieversArrayTemp[i][3]) + Number.parseInt(this.state.myAchieversArrayTemp[i][4]);
				this.setState({ prestige: tempPres });
			}
		}			
		this.loadGems();	
	}
	
	
  
  
	async loadBlockchainData() {
		window.web3 = new Web3(window.ethereum)
	//	window.ethereum.enable()
		window.ethereum.request({
          method: "eth_requestAccounts",
        });
		this.showModalFrontPage();
		const accounts = await window.web3.eth.getAccounts()
		const {ethereum} = window;
		const networkId = await ethereum.request({ method: "net_version",});
		console.log("checking network here " + networkId + " - " + window.Configs.NETWORK_ID);
		if(networkId == window.Configs.NETWORK_ID){
			
//			console.log(networkId)
			this.setState({ networkId });		
			
			if (typeof accounts == 'undefined'){
				this.setState({account: 0x0000000000000000000000000000000000000000});
//			console.log("account not defined");
			}
			else {
//				console.log("account is defined");
//				console.log (JSON.stringify(accounts));
				this.setState({account: accounts[0] })				
			}

			//init contract
			const alfContract = new window.web3.eth.Contract(ABI, CONTRACT_ADDRESS)
			this.setState({ alfContract })
			
			const gemTradeContract = new window.web3.eth.Contract(GEMTRADEABI, GEM_TRADING_ADDRESS)
			this.setState({ gemTradeContract })
						
			const tokenContract = new window.web3.eth.Contract(TOKENABI, TOKEN_CONTRACT_ADDRESS)
			this.setState({ tokenContract });
			
			// get balance
			const balance = await alfContract.methods.balanceOf(accounts[0]).call()
			this.setState({ balance })
	//		console.log("balance? " + balance);
	
			const total = await alfContract.methods.totalSupply().call()
			this.setState({ total });
			
			const hasStarted = await alfContract.methods.hasStarted().call()
			this.setState({ hasStarted })
	//		console.log("started? " + hasStarted);
	
			const hasEnded = await alfContract.methods.hasEnded().call()
			this.setState({ hasEnded })
			
			const distance = await alfContract.methods.raceDistance().call()
			this.setState({ distance })
			
			const raceNumber = await alfContract.methods.raceNumber().call()
			this.setState({ raceNumber })
			
			const namesCount = await alfContract.methods.namesCount().call();
			this.setState({ namesCount })
			
			const winnerIdsLength = await alfContract.methods.getWinnerIdsLength().call();
			this.setState({ winnerIdsLength });
			
			const fuelAllowance = await alfContract.methods.fuelAllowance().call();
			this.setState({ fuelAllowance });
			
			const raceReady = await alfContract.methods.raceReady().call();
			this.setState({ raceReady });
			
			const hasPostedWinner = await alfContract.methods.hasPostedWinner().call();
			this.setState({ hasPostedWinner });
			
			const userPrestige = await alfContract.methods.prestige(this.state.account).call();
			this.setState({ userPrestige });
			
			const achieversLength = await alfContract.methods.getAchieversLength().call();
			this.setState({ achieversLength });
			
			const gemOwnerCount = await tokenContract.methods.getGemTotalValue(this.state.account).call();
			this.setState({ gemOwnerCount });
	//		this.setState({ gemOwnerCount });
//			const achievements = await alfContract.methods.achievers(1).call();
//			this.setState({ achievements });			
//			console.log("achievers length = " + this.state.achievements[1] +" " + this.state.achieversLength);
			
		//	const allPrestige = await alfContract.methods.prestige.call();
		//	this.setState({ allPrestige });
			
			const raceType = await alfContract.methods.raceType().call();
			
			if(raceType == 1){this.setState({ raceType: "Standard"});}
			else if(raceType == 2){this.setState({ raceType: "Beginners" });}
			else if(raceType == 3){this.setState({ raceType: "Maiden" });}
			else if(raceType == 4){this.setState({ raceType: "Championship" });}
			else if(raceType == 5){this.setState({ raceType: "Featured" }); }

			const entryFee = await alfContract.methods.entryFee().call();
			this.setState({ entryFee: entryFee });

			const prize = await alfContract.methods.prize().call();
			this.setState({ prize: prize });
			
			const cooldown = await alfContract.methods.globalCooldown().call();
			this.setState({ cooldown: cooldown/3600 });
			
			const winPrestige = await alfContract.methods.winPrestige().call();
			this.setState({ winPrestige: winPrestige });
			
			const currentApproval = await tokenContract.methods.isApprovedForAll(this.state.account, GEM_TRADING_ADDRESS).call();
			this.setState({ currentApproval });

			const gemOwnersLength = await tokenContract.methods.getGemOwnersLength().call();
			this.setState({ gemOwnersLength: gemOwnersLength });
	
			for (var i = 0; i < this.state.total; i++){ 
				const thisOwner = await alfContract.methods.ownerOf(i).call();
				this.setState(previousAState => ({
					allOwnersArray: [...previousAState.allOwnersArray, thisOwner]
				}));
			}

			for(var i = 0; i < this.state.gemOwnersLength ; i++){
				const thisGemOwner = await tokenContract.methods.gemOwners(i).call()
				this.setState(previousGState => ({
					gemOwnersArray: [...previousGState.gemOwnersArray, thisGemOwner]
				}));
				console.log("Gem owners = " + JSON.stringify(this.state.gemOwnersArray[i]));
			}
			
			
			for(var i = 0; i < this.state.winnerIdsLength ; i++){
				const winnerNow = await alfContract.methods.winners(i).call()
				// load myAliensArray with all winning aliens
				this.setState(previousWState => ({
					myWinnersArray: [...previousWState.myWinnersArray, winnerNow]
				}));
//				console.log("my winners arrasy = " + this.state.myWinnersArray[i].winnerId);
			}
			
			console.log("is this thing on");
			for(var i = 0; i < this.state.total ; i++){
				const thisAlien = await alfContract.methods.aliens(i).call()
				// load myAliensArray with all aliens
				this.setState(previousWState => ({
					allAliensArray: [...previousWState.allAliensArray, thisAlien]
				}));
			}
/*
			for(var i = 0; i < this.state.achieversLength ; i++){
	//			const thisName = await alfContract.methods.nameServer(i).call()
				if(this.state.myAchieversArray[i][5] != "not set"){
					
				// load myAliensArray with all aliens
				console.log("names = " + thisName
				this.setState(previousNamesState => ({
					allNamesArray: [...previousNamesState.allNamesArray, thisName]
				}));
			}
*/			
			const startTime = await alfContract.methods.startTime().call();
			this.setState({ startTime });
	//		console.log("startTime1 " + startTime);
			const date = new Date(startTime * 1000);
			let formattedStartTime;
			if (startTime != 0) {
			formattedStartTime = date.getDate()+
			  "/"+(date.getMonth()+1)+
			  "/"+date.getFullYear()+
			  " "+date.getHours()+
			  ":"+date.getMinutes()+
			  ":"+date.getSeconds();
	//		  console.log("formattedStartTime " + formattedStartTime);
			}
			  this.setState({ formattedStartTime });

			let distanceCovered =  ( Math.round(Date.now()/1000) - this.state.startTime) * this.state.engine ; // fix this//////////////////////////////////////////
	//		console.log("time now " + Math.round(Date.now()/1000));
	//		console.log("startTime2 " + this.state.startTime);
	//		console.log("mySpeed " + this.state.engine);
	//		console.log("distance covered " + distanceCovered);
			this.setState({ myTime:  formattedStartTime }); // fix this
			
			if(distanceCovered >= this.state.distance){			
				//	console.log("race complete top");			
				// mytime is the time it took me to complete the race			
				//	raceComplete = true;			
			}	

			const fieldConditions = await alfContract.methods.fieldConditions().call();
			this.setState({ fieldConditions });		
	//		  console.log("field conditions = " + this.state.fieldConditions);
			

	///////////////////////////////////////////
			// get the number of aliens I own

			const myTotal = await alfContract.methods.balanceOf(this.state.account).call();
	//		console.log("myTotal " + myTotal);
		
			// find all my aliens
			const myAliens = await alfContract.methods.walletOfOwner(this.state.account).call()	

	//		console.log("here my");
	//		console.log(myAliens);
			let racers = [];
			let racersName = [];
			let finishers = [];
			let finishersName = [];
			let finishersTime = [];
//			let winner;
			let winnerIndex;
			let winnerName;
			let winnerTime;
			let winnerId;
			//let leaderboard = []
			let allNames = [];
			let racerReward = [];
			let finisherOwners = [];
			
			for(var n = 0; n < namesCount; n++){ // used for checking if a name is free for claiming
				const nameNow = await alfContract.methods.registeredNames(n).call();
				allNames.push(nameNow);
				// load myNamesArray with all my names
				this.setState({ allNames });
			}		
			
			const myUserName = await alfContract.methods.nameServer(this.state.account).call();  // grabbing my usersname
			let registeredUserHandle;
			if(myUserName != 0){
				registeredUserHandle = myUserName;
				this.setState({ registeredUserHandle });
			} else {
				registeredUserHandle = this.state.account.slice(0, -34) + "...";
				this.setState({ registeredUserHandle });
			}


			
			for(var h = 0; h < myTotal; h++){    // all my aliens into array myAliensArray[]
				const alienNow = await alfContract.methods.aliens(myAliens[h] ).call()
				// load myAliensArray with all my aliens
				this.setState(previousState => ({
					myAliensArray: [...previousState.myAliensArray, alienNow]
				}));
			}
			
			for (var v = 0; v < this.state.achieversLength; v++){  //all achievers into array myAchieversArray
				var achiever = await alfContract.methods.achievers(v).call()
				this.setState(previousState1 => ({
				myAchieversArray: [...previousState1.myAchieversArray, achiever]	
			}));		
			}
			
			
			let myWins = 0;
			for (let m = 0; m < this.state.myAliensArray.length; m++) {
				if(this.state.myAliensArray[m].wins > 0)
				myWins = Number(myWins) + Number(this.state.myAliensArray[m].wins);
//			console.log("Myaliensarraywins = " + myWins);
			}
			
			let myStarts = 0;
			for (let t = 0; t < this.state.myAliensArray.length; t++) {
				if(this.state.myAliensArray[t].races > 0)
				myStarts = Number(myStarts) + Number(this.state.myAliensArray[t].races);
//			console.log("MyaliensarrayStarts = " + myStarts);
			}
			
			let myFinishes = 0;
			for (let s = 0; s < this.state.myAliensArray.length; s++) {
				if(this.state.myAliensArray[s].finishes > 0)
				myFinishes = Number(myFinishes) + Number(this.state.myAliensArray[s].finishes);
	//		console.log("Myaliensarraywins = " + myFinishes);
			}
			
			let aliensPres = 0;
			let winsPres = 0;
			let racesPres = 0;
			let finsPres = 0;
			
			
			if(this.state.myAliensArray.length > 0){
				for(let r = 0; r < this.state.myAliensArray.length; r++){
					winsPres += Number(this.state.myAliensArray[r].wins) * 2;
					aliensPres = myAliens.length * 5;
					racesPres += Number(this.state.myAliensArray[r].races);
					finsPres += Number(this.state.myAliensArray[r].finishes);
				}
			}

			let prestige = winsPres + aliensPres + racesPres + finsPres + Number(this.state.userPrestige);
	//		console.log ("prestige = " + winsPres + aliensPres + racesPres + namePres);
			this.setState({ prestige: prestige });		
	//		console.log(this.state.account);

			for(var i = 0; i < total; i++){    // array of all aliens in circulation globalAliensArray[]
				const globalAliens = await alfContract.methods.aliens(i).call()
				this.setState(previousGState => ({
					globalAliensArray: [...previousGState.globalAliensArray, globalAliens]
				}));		
			}
			
			
			for(var i = 0; i < this.state.total; i++){    // array of all aliens entered into the race enteredAliens[]

				const enteredAliens = await alfContract.methods.aliens(i).call()
	//			console.log("entered aliens " + enteredAliens[i]);
				if (enteredAliens.isRacing){  // aliens get entered into list of racers
					racers.push(enteredAliens.id);
					const ownerOf = await alfContract.methods.ownerOf(enteredAliens.id).call();
					
					
					
					
					racerReward.push(ownerOf);
					console.log("id = " + enteredAliens.id + "owner address = " + ownerOf);
					racersName.push(enteredAliens.alf_name)
					console.log("entered aliens names " + enteredAliens.alf_name + " " + enteredAliens.id);
				

				}	
				
				this.setState({racers});
				this.setState({racersName});
				this.setState({racerReward: racerReward});

				
				if ((enteredAliens.hasSubmitted == true) && (enteredAliens.myRaceNumber == raceNumber)){  // finished racers get entered into list of finishers			
					finishers.push(enteredAliens.id);
					finishersName.push(enteredAliens.alf_name);
					finishersTime.push(enteredAliens.recentTime);
					const finisherOwnerOf = await alfContract.methods.ownerOf(enteredAliens.id).call();
					finisherOwners.push(finisherOwnerOf);
					console.log("pushing finishers " + i);
					console.log("finishers 1 " + finisherOwners[0]);
					console.log("finishers 2 " + finisherOwners[1]);
				//	this.finish(enteredAliens.id);
				}
				this.setState({ finishers });
				this.setState({ finishersName });
				this.setState({ finishersTime });
				this.setState({ finisherOwners });
	//			console.log("finishers " + finishersTime[1]);
				
				winnerTime = Math.min(...this.state.finishersTime);// this is a time (shortest time)
	//			console.log("winner time " + winnerTime);
				winnerIndex = this.state.finishersTime.indexOf(winnerTime.toString());
				winnerName = this.state.finishersName[winnerIndex];
				winnerId = this.state.finishers[winnerIndex];
	//			console.log("winner index " + winnerIndex);
	//			console.log("winner name " + winnerName);
				this.setState({ winnerTime });
	//			this.setState({ finishers });
				this.setState({ winnerName });
				this.setState({ winnerId });

			}
console.log("racer reward " + racerReward.length);

			this.setState({	thisLeg: "?"});  // reset the strategy to default
	//		let entered;
		//	this.setState({screenOn: true });
			let leg1 = 0
			this.setState({ leg1 });
			let leg2 = 0
			this.setState({ leg2 });
			let leg3 = 0
			this.setState({ leg3 });
			let leg4 = 0
			this.setState({ leg4 });
			let leg5 = 0
			this.setState({ leg5 });
			let leg6 = 0
			this.setState({ leg6 });
			let leg7 = 0
			this.setState({ leg7 });
			let leg8 = 0
			this.setState({ leg8 });
			let leg9 = 0
			this.setState({ leg9 });	
			let finalSprint = 0;	// leg 10
			this.setState({ finalSprint });
			this.setState({ savedStrategy: ["","","","","","","","","",""]});
			
			this.setState({	thisField: "?"});  // reset the strategy to default
	//		let entered;
		//	this.setState({screenOn: true });
			let field1 = 0
			this.setState({ field1 });
			let field2 = 0
			this.setState({ field2 });
			let field3 = 0
			this.setState({ field3 });
			let field4 = 0
			this.setState({ field4 });
			let field5 = 0
			this.setState({ field5 });
			let field6 = 0
			this.setState({ field6 });
			let field7 = 0
			this.setState({ field7 });
			let field8 = 0
			this.setState({ field8 });
			let field9 = 0
			this.setState({ field9 });
			let finalField = 0
			this.setState({ finalField });
			this.setState({ savedConditions: ["","","","","","","","","",""]});
			this.setState({ contractIndicator: "TAR Contract: " + CONTRACT_ADDRESS });
		} else {
			this.setState({ Open: true });
			    console.log("Wrong network = " +  networkId);   
			
		}
		
		
	};
getName(address){
		var tempNameArray = JSON.parse(JSON.stringify(this.state.myAchieversArray));
			for(var i = 0; i < this.state.achieversLength ; i++){
				console.log("my address = " + JSON.stringify(tempNameArray[i][5]));
				if(this.state.myAchieversArray[i][6] == address){
			//	return this.state.myAchieversArray[i][5];
				this.setState({ gotName: this.state.myAchieversArray[i][5] });
				var tempPres = Number.parseInt(this.state.myAchieversArray[i][3]) + Number.parseInt(this.state.myAchieversArray[i][4]);
			}
		}
	}
			

	//////////////////////////////////////////////***************************************
	//start test functions *******************************************
	//////////////////////////////////////////////***************
	getLeaderboardData(){
		
		var tempAchieversArray = JSON.parse(JSON.stringify(this.state.myAchieversArray));
		console.log("my achievers = " + JSON.stringify(this.state.myAchieversArray[0]));
		for (var a = 0; a < this.state.achieversLength; a++){  // set truncated addres as playerName if "not set"
			if (tempAchieversArray[a][5] == "not set"){
				tempAchieversArray[a][5] = tempAchieversArray[a][6].toString().slice(0, -34);
			}
		}	
		var nftAchieversArray = JSON.parse(JSON.stringify(tempAchieversArray));
		for(var b = 0; b < this.state.achieversLength; b++){
			for(var c = 1; c < ( this.state.achieversLength - b  ); c++){
				if(parseInt(nftAchieversArray[c-1][1]) < parseInt(nftAchieversArray[c][1])){
					var temp = nftAchieversArray[c - 1];
					nftAchieversArray[c-1] = nftAchieversArray[c];
					nftAchieversArray[c] = temp; 
				}
			}
		}
		this.setState({ myAchieversArrayNFT: nftAchieversArray });
		
		var presAchieversArray = JSON.parse(JSON.stringify(tempAchieversArray));
		for(var d = 0; d < this.state.achieversLength; d++){
			for(var e = 1; e < ( this.state.achieversLength - d  ); e++){
				console.log("prestiges = " + presAchieversArray[e-1][4] + " - " + presAchieversArray[e-1][3]);
				if(parseInt(presAchieversArray[e-1][3])+parseInt(presAchieversArray[e-1][4]) < parseInt(presAchieversArray[e][3])+parseInt(presAchieversArray[e][4])){
					var temp = presAchieversArray[e - 1];
					presAchieversArray[e-1] = presAchieversArray[e];
					presAchieversArray[e] = temp; 
				}
			}
		}
		this.setState({ myAchieversArrayPres: presAchieversArray });	
		console.log("show all pres achieversssssssssssssssssssssssssssss = "  + JSON.stringify(this.state.myAchieversArrayPres));
		
			var gemAchieversArray = JSON.parse(JSON.stringify(tempAchieversArray));
			for(var f = 0; f < this.state.achieversLength; f++){
				for(var g = 0; g < this.state.gemOwnersLength; g++){
					if(this.state.gemOwnersArray[g][0] == gemAchieversArray[f][6]){  // gem owner is listed in achievers
						gemAchieversArray[f][4] = this.state.gemOwnersArray[g][1];                    // if no name set in achievers
					} 
				}
			}
//			this.setState({ myGemsValue: 
			
//			var gemResortArray = this.state.gemAchieversArray;  // use this if there are issues with gem sorting
			for(var i = 0; i < this.state.achieversLength; i++){
				for(var j = 1; j < ( this.state.achieversLength - i  ); j++){
					if(parseInt(gemAchieversArray[j-1][4]) < parseInt(gemAchieversArray[j][4])){
						var temp = gemAchieversArray[j - 1];
						gemAchieversArray[j-1] = gemAchieversArray[j];
						gemAchieversArray[j] = temp; 
					}
				}
			}	
			this.setState({ gemAchieversArray });
			
			var winAchieversArray = JSON.parse(JSON.stringify(tempAchieversArray));
			
			for(var b = 0; b < this.state.achieversLength; b++){
				for(var c = 1; c < ( this.state.achieversLength - b  ); c++){
					if(parseInt(winAchieversArray[c-1][2]) < parseInt(winAchieversArray[c][2])){
						var temp = winAchieversArray[c - 1];
						winAchieversArray[c-1] = winAchieversArray[c];
						winAchieversArray[c] = temp; 
					}
				}
			}
			
			this.setState({ winAchieversArray });
			console.log("array = " + JSON.stringify(winAchieversArray));
			
			
			var winAliensArray = JSON.parse(JSON.stringify(this.state.allAliensArray));
			
			for(var b = 0; b < this.state.total; b++){
				for(var c = 1; c < (this.state.total - b); c++){
					if(parseInt(winAliensArray[c-1][5]) < parseInt(winAliensArray[c][5])){
						var temp = winAliensArray[c - 1];
						winAliensArray[c-1] = winAliensArray[c];
						winAliensArray[c] = temp; 
					}
				}
			}
			
			this.setState({ winAliensArray });
			console.log("array = " + JSON.stringify(winAliensArray[0][0]));
			

	}
			/////////////////////////////////////////
			//end test functions************************
			///////////////////////////////////////////
	
	
	async giveApproval(theBool){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.tokenContract.methods
		.setApprovalForAll(GEM_TRADING_ADDRESS, theBool)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
			window.location.reload(false);
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadPartBlockchain();
		});			
	}
	
	async directAirdrop(receiver, id, qty){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.airdrop(receiver, id, qty)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
		})
		.then((receipt) => {
			console.log(receipt);
		});			
	}
	
	async loadAirdrop(receiver, qty){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.addRecipients(receiver, qty)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
		})
		.then((receipt) => {
			console.log(receipt);
		});			
	}	
	
	async checkClaim(){
			const gemsClaimable = await this.state.gemTradeContract.methods.recipients(this.state.account).call();
			this.setState({ gemsClaimable });		
		}
	
	async claim(){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.claim()
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
			window.location.reload(false); 		
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadPartBlockchain();
		});			
	}
	
	async freebie(id, qty){// remember to remove this
		console.log("My Address = " + this.state.account);
		console.log("Cont Address = " + this.state.gemTradeContract);
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.giveFreebie(id, qty)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadGems();
		});			
	}	
	async spendGem(){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.takeOwn(0)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadPartBlockchain();
		});			
	}
	
	async swapGemsThree(swapIn, getBack){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.swapThree(swapIn, getBack)
		.send({
			gasLimit: String(gasLimit),
			to: GEM_TRADING_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
			window.location.reload(false); 
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadGems();	
		});			
	}
	async swapGemsPair(swapIn, getBack){
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.gemTradeContract.methods
		.swapPair(swapIn, getBack)
		.send({
			gasLimit: String(gasLimit),
			to: GEM_TRADING_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			 if(err.message.includes("caller is not token owner nor approved")){
				this.setState({ text: "You probably havent approved this contract for swapping your gems" });
				this.setState({ Open: true });
				this.loadGems();
			 } else {
				this.setState({ text: err.message });
				this.setState({ Open: true });
				window.location.reload(false);
			}
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadGems();
		});			
	}

		
	async joinRace(alfId, gemUsed) { 
		let gasLimit = window.Configs.GAS_LIMIT;
		let cost = this.state.entryFee;
		let thisToken = parseInt(this.state.id);
		await this.state.alfContract.methods
		.enterRace(1, thisToken, gemUsed, 2)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,
			value: cost,			
		})
		 .once("error", (err) => { 
			console.log(err);
			window.location.reload(false);
			this.showModalAdminRace();
		})
		.then((receipt) => {
			console.log(receipt);
			this.showModalPlot(this.state.id, this.state.name, "item 3", "item 4");
		});		
	}
	
	async unjoinRace(alfId) {  // alien owner can remove himself from the next race
		console.log("handling race Unplacement");
		let gasLimit = window.Configs.GAS_LIMIT;
		let thisToken = parseInt(this.state.id) ;
console.log("state " + this.state.id);
console.log("Gas limit: ", gasLimit);
		await this.state.alfContract.methods
		.enterRace(0, thisToken, 0, 0)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,
			
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ Open: true });
			window.location.reload(false);				
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadPartBlockchain();
		});		
	}	
	async setStrategy(strategy, fuel) {  // alien owner can set strategy after joining race (must join race first)
		let gasLimit = window.Configs.GAS_LIMIT;
		let thisToken = parseInt(this.state.id);
		await this.state.alfContract.methods
		.setStrategy(strategy, thisToken, fuel)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
			
		})
		.then((receipt) => {
			console.log(receipt);
//			window.location.reload(false);
			this.loadPartBlockchain();
			this.hideModalPlot();
		//	this.calculateEngine();
			
		});			
	}
	async setConditions(field, fuelAllowance, distance, setRaceType, setEntryFee, setPrize, setCooldown, setPrestige) {
		let gasLimit = window.Configs.GAS_LIMIT;
		let thisToken = parseInt(this.state.id);
		let thisEntryFee = String(setEntryFee * 1000000000000000000);
		let thisPrize = String(setPrize * 1000000000000000000);
		let thisCooldown = setCooldown * 3600;
		let typeToInt;
		if(setRaceType == "Standard"){typeToInt = 1;}
		if(setRaceType == "Beginners"){typeToInt = 2;}
		if(setRaceType == "Maiden"){typeToInt = 3;}
		if(setRaceType == "Championship"){typeToInt = 4;}
		if(setRaceType == "Featured"){typeToInt = 5;}
		console.log(this.state.cooldown)
console.log("Gas limit: ", gasLimit);

console.log(" new field  = " + field)
		await this.state.alfContract.methods
		.setFieldConditions(field, fuelAllowance, distance, typeToInt, thisEntryFee, thisPrize, thisCooldown, setPrestige)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
		})
		.then((receipt) => {
			console.log(receipt);
			
			window.location.reload(false);
//			this.calculateEngine();
		});			
	}	
	async mintAlien(qty, name) {
		let gasLimit = window.Configs.GAS_LIMIT;
		let cost = window.Configs.COST;
		let totalGasLimit = String(gasLimit * qty);
		let totalCostWei = String(cost * qty);
//		let thisToken = parseInt(this.state.id);
	console.log("Gas limit: ", gasLimit);
		await this.state.alfContract.methods
		.mint(qty, name)
		.send({
			gasLimit: String(totalGasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,
			value: totalCostWei,			
		})
		 .once("error", (err) => {
			console.log(err.message);
			if(err.message.includes("contract is paused")){
				this.setState({ text: "The contract is paused" });
				this.setState({ Open: true });
			} else {
				this.setState({ text: err.message });
				this.setState({ Open: true });
			}
		})
		.then((receipt) => {
			console.log(receipt);
			this.loadPartBlockchain();
		});			
	}
	
	async startRace() {  // project owner starts race
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.alfContract.methods
		.startRace()
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,		
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ Open: true });
		})
		.then((receipt) => {
			console.log(receipt);
			window.location.reload(false);
		});			
	}	
	async endRace() {  // project owner ends race
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.alfContract.methods
		.endRace()
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,		
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ Open: true });
			window.location.reload(false);	
		})
		.then((receipt) => {
			console.log(receipt);
			window.location.reload(false);
		});			
	}		
	async submitFinish(time) {  // NFT owners submit theit times after ending race
		let gasLimit = window.Configs.GAS_LIMIT;
		let thisToken = parseInt(this.state.id);
		await this.state.alfContract.methods
		.submitFinish(time, thisToken)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ Open: true });
			window.location.reload(false);	
		})
		.then((receipt) => {
			console.log(receipt);
		//	this.state.submitted = true;
			this.hideModalHelm();
			this.loadPartBlockchain();
		});			
	}
	
	async post(raceNum, winId, winTime, winName, OwnName, isChampionship) {  //  project owner posts results of recent race
		let gasLimit = window.Configs.GAS_LIMIT;
		await this.state.alfContract.methods
		.postWinner(raceNum, winId, winTime, winName, OwnName, isChampionship)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
		})
		.then((receipt) => {
			console.log(receipt);
			window.location.reload(false);
		});			
	}
	
	async claimName(newName) {  // account owner claims unique name
		let gasLimit = window.Configs.GAS_LIMIT;
		let cost = window.Configs.NAME_COST;
		await this.state.alfContract.methods
		.changeName(newName, "empty", true)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,
			value: cost,			
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
			window.location.reload(false);
		})
		.then((receipt) => {
			console.log(receipt);
			this.hideModalRegisterName();
			this.loadPartBlockchain();
		});	

	console.log("placeholder for claim name " + newName);		
	}
	async changeName(newName, oldName) {  // account owner can change unique name
		let gasLimit = window.Configs.GAS_LIMIT;
		let cost = window.Configs.NAME_COST;
		await this.state.alfContract.methods
		.changeName(newName, oldName, false)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
			value: cost,
		})
		 .once("error", (err) => {
			console.log(err);
			this.setState({ text: err.message });
			this.setState({ Open: true });
			window.location.reload(false);
		})
		.then((receipt) => {
			console.log(receipt);
			this.hideModalRegisterName();
			this.loadPartBlockchain();
		});	

//	console.log("placeholder for claim name " + name);		
	}
	async changeAlienName(id, newName) {  // alien owner can change aliens name
		let gasLimit = window.Configs.GAS_LIMIT;
//		let thisToken = parseInt(this.state.id);

		await this.state.alfContract.methods
		.changeAlienName(id, newName)
		.send({
			gasLimit: String(gasLimit),
			to: CONTRACT_ADDRESS,
			from: this.state.account,	
		})
		 .once("error", (err) => {
			console.log(err);
		})
		.then((receipt) => {
			console.log(receipt);
			this.hideModal();
			this.loadPartBlockchain();
		});		
	}
	
	decideIfCanEnter(myWins, myStarts, myCooldown){	
		let coolVal = this.calculateTime(myCooldown)	
	console.log("my cooldown " + coolVal);
	console.log(this.state.raceType);
		if (this.state.raceType == "Standard"){
			if (coolVal > 0){
				this.state.isAllowedToEnter = false;
			} else{
			this.setState({ isAllowedToEnter: true });
			}
		} else if(this.state.raceType == "Beginners"){
			if (myStarts < 1){
				this.setState({ isAllowedToEnter: true });
			}
		} else if(this.state.raceType == "Maiden"){
			if (myWins < 1){
				this.setState({ isAllowedToEnter: true });
			}
		} else if(this.state.raceType == "Championship"){
			if (myWins >= 1){
				this.setState({ isAllowedToEnter: true });
			}
		} else if (this.state.raceType == "Featured"){
			this.setState({ isAllowedToEnter: true });
		}
		console.log("decide if can enter = " + this.state.isAllowedToEnter);
	}
	checkChamp(champ){
		if(champ){
			return <h3 style={{color: "gold"}}>Champion</h3>;
		}
	
	}
	handleMyAlfMint(event) {
		const regex  = /[!,@,#,$,%,^,&,*,(,),",:,;,+,?,{,}]/;
		if (regex.test(event.target.value)) {
			this.setstate ({ Open: true });
			this.setState ({ text: "Only Letters and numbers and underscore and dash allowed in Name field" });
			event.target.value = "";
		} else {
			this.setState({ alienName: event.target.value });
		}	
	}
	handleMyAlfQty(event) {
		if(isNaN(event.target.value)){
			console.log("not a number");
			this.setState ({ Open: true });
			this.setState ({ text: "Quantity must be a number" });
			event.target.value = "";
		}
		else {
			this.setState({ mintQty: event.target.value });
		}
	}		

	async handleMint(event) {
		event.preventDefault();
//		let alienName = window.Configs.ALIEN_NAME;
		if((this.state.mintQty == null) || (this.state.alienName == null)){
			this.setState ({ Open: true });
			this.setState ({ text: "Enter a name and quantity" });
		} else if (this.state.mintQty > window.Configs.MAX_MINT_AMT){
			this.setState ({ Open: true });
			this.setState ({ text: "exceeds Max Mint Amt: " +  window.Configs.MAX_MINT_AMT});
		} else{
			this.mintAlien(this.state.mintQty, this.state.alienName);
			console.log("minted " + this.state.mintQty + " aliens named " + this.state.alienName + " for " + this.state.account);
			this.hideModalNewAlf();
		}		
	}

	handleAlfNameInput(event) {
		const regex  = /[!,@,#,$,%,^,&,*,(,),",:,;,+,?,{,}]/;
		if (regex.test(event.target.value)) {
			this.setState ({ Open: true });
			this.setState ({ text: "Only Letters and numbers and underscore and dash allowed in Name field" });
			event.target.value = "";
		} else{
			this.setState({ newAlienName: event.target.value })
			console.log(event.target.value);
		}		
	}	

	async handleAlfNameChange(event) {
		event.preventDefault();
		
		if((this.state.newAlienName == "") || (this.state.newAlienName == null)){
//		if((event.target.value == "") || (event.target.value == null)){
			console.log(event.target.value);
			this.setState ({ Open: true });
			this.setState ({ text: "Name cannot be blank" });
		} else {
			this.changeAlienName(this.state.id, this.state.newAlienName);
		}
	}


	handleClickSprint(event){  // clicking in field to activate the field
		event.preventDefault();		
		this.setState({ thisLeg: "Final" });	
	}
	
		handleSprintFuel(event){  // typing the field to input the values
			event.preventDefault();
			if(isNaN(event.target.value)){
				event.target.value = "";
				this.setState ({ Open: true });
				this.setState ({ text: "Please use numerical value between 0 and 100" });
			} else if ((event.target.value >100)||(event.target.value < 0)){
				event.target.value = "";
				this.setState ({ Open: true });
				this.setState ({ text: "Please use value between 0 and 100" });
			} else{
				this.setState({ fuelApplied: event.target.value });
			}
		}	
	
		handleSprint(){  // this on clicking the orange submit button
	//		event.preventDefault();
	//		this.setState({ fuelApplied: event.target.value });
		//	this.setState({ fuelLeft });
				for (let f = 0; f < this.state.fArray.length; f++){
				this.state.totalFuelUsed = this.state.totalFuelUsed + this.state.fArray[f];
	//			console.log(f + " fArray f = " + this.state.fArray[f]);
	//			this.setState({ totalFuelUsed });
			}
	//		console.log("fuel account = "  + this.state.totalFuelUsed + " . " + this.state.fuelApplied);
			this.setState({ savedFuelApplied: this.state.fuelApplied });
		}
	
		handleWhatsThis() {
			this.showModalWhatsThis();
		}
		
		async sprintForm(){
//			finalFuelBool = true;
//			console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh" + this.state.finalFuelBool);
			this.setState({ finalFuel : <styles.FinalFuel>	<Form onSubmit={(e) => {this.handleSprint(e)}}>
							<Form.Field onClick={(e) => {this.handleClickSprint(e)}}>
								<label>Input percentage</label>
								<input placeholder= "Fuel to use"  onChange={(e) => {this.handleSprintFuel(e)}} />
							</Form.Field>
							<p>{<styles.SubmitFuelButton onClick={() => {this.handleSprint()}}>Approve Fuel</styles.SubmitFuelButton>}</p>
							<p>{<styles.WhatsThisButton onClick={() => {this.handleWhatsThis()}}>Whats this?</styles.WhatsThisButton>}</p>
						</Form>	
		</styles.FinalFuel> });}

//                 let inertiaButton = <styles.PlotEngineButton  className="trackDown" onClick={() => {this.setEngine(1); this.setState({})} }>Inertia Drift</styles.PlotEngineButton>;
		
		//----------------------------------------------------------------------------------------------------the fuel variable here-------------------------------------//
		
		async postResults(winner){
			console.log("posting winner ");
			function Posting(race, id, time, name, trainer){
				this.Race = race;
				this.WinnerId = id;
				this.Time = time;
				this.WinnerName = name;
				this.Trainer = trainer;			
			}
			
			let winnerAccount = await this.state.alfContract.methods.alienToOwner(this.state.winnerId).call();
			console.log("winner account = " + winnerAccount);
			let isChampionship = false;
			if(this.state.raceType == "Championship"){
				isChampionship= true;
			}
			this.loadAirdrop(this.state.finisherOwners, 1)
			this.post(this.state.raceNumber, this.state.winnerId, this.state.winnerTime, this.state.winnerName, winnerAccount, isChampionship);
	//	this.post(this.state.raceNumber, 1, 1, "nobody", '0x0000000000000000000000000000000000000000', 0);
			this.clearLastRace();
		}
	

	
		handleMyNameCheck(event) {
	//		console.log("Name check = " + event.target.value);
			this.setState({ myNameCheck: event.target.value })
		}
			
		async handleForm(event) {
	//		console.log("Name choice = " + event.target.value);
	//		console.log("Name choice state  = " + this.state.myNameCheck);
			event.preventDefault();
			if((this.state.myNameCheck == null) || (this.state.myNameCheck == "")){
				alert("Please enter a name choice!");
			} else {
				let nameApproved = false;
				if(this.state.allNames.indexOf(this.state.myNameCheck) != -1)
				{
		//			console.log("you cant have that name");
					alert("Sorry that name is taken") 
					nameApproved = false;
					this.setState({ nameApproved })
				} else{
	//				console.log(" name approved");
					nameApproved = true;
					this.setState({ nameApproved })
				}
			}
		}
	
		async clearLastRace(){

			this.finishers = [];
			this.finishersName = [];
			this.finishersTime = [];
			this.setState({ finishers: [] });
			this.setState({ finishersName: [] });
			this.setState({ finishersTime: [] });
	//		console.log("clearing last race");
		}
		async loadBalance() {
			const balance = await this.state.alfContract.methods.balanceOf(this.state.account).call()
			this.setState({ balance })
		}
		
		handleSomeTest(cool, now){
			const differenceS = now - cool;
			const hours = Math.floor(differenceS / 3600);
			const mins = Math.floor((differenceS / 60) % 60);
			const secs = Math.floor(differenceS % 60);
			const timeString = hours + "hr " + mins + "min " + secs + "sec";
			return timeString;
		}		
			
		addSomeShit(a,b){
//			var c = a + b;
//			console.log(c);
		}
		
		closeScreen() {
			this.setState({screenOn: false});
		}
		openScreen() {
			this.setState({screenOn: true});
		}
		
		async setEngine(engine) {
			let stringStrategy;
//			console.log("engine = " + engine);
			if(engine == 1){
				stringStrategy = "Inertia Drift";
//				console.log("string strat = " + stringStrategy);
			} else if(engine == 2){
				stringStrategy = "Impulse Engines";
			} else if(engine == 3){
				stringStrategy = "Warp Drive";
			} else if(engine == 4){
				stringStrategy = "Hyper Space";
			} else if(engine == 5){
				stringStrategy = "Shields";
			}
			
//			console.log("string ssstrat = " + stringStrategy);
			
			this.state.myStrategy[this.state.thisLeg - 1] = engine;
			this.state.stringMyStrategy[this.state.thisLeg - 1] = stringStrategy;
			this.state.enginesAdded++;
		}
		
		lockCourse(){ // strategy set by user
			const strategy = this.state.myStrategy.join("");
//			console.log(strategy);
			this.setStrategy(this.state.myStrategy.join(""), this.state.savedFuelApplied);
//			console.log("saved fuel applied before = " + this.state.savedFuelApplied);
			this.setState({ fuel: this.state.savedFuelApplied });
//			console.log("saved fuel applied after = " + this.state.savedFuelApplied);
		}
		
		lockField() { // field conditions set by owner
			this.setConditions(this.state.myConditions.join(""), this.state.newFuelAllowance, this.state.newDistance, this.state.setRaceType, this.state.setEntryFee, this.state.setPrize, this.state.setCooldown, this.state.setPrestige);
		}
		
		async setFieldConditions(field){  // field conditions set by owner
			let stringField;
			
			if(field == 1){
				stringField = "All Clear";
			} else if(field == 2){
				stringField = "Meteor Field";
			} else if(field == 3){
				stringField = "Space Pirates";
			} else if(field == 4){
				stringField = "Pirates Radar";
			} else if(field == 5){
				stringField = "Star Gravity Field";
			} else if(field == 6){
				stringField = "Hyperspace Wormhole";
			} else if(field == 7){
				stringField = "Plasma Anomaly";
			}
			this.state.fieldAdded++;
//			console.log("Field settings " + field + " " + stringField);
			this.state.myConditions[this.state.thisField - 1] = field;
//			console.log("my conditions length " + this.state.myConditions.length);
			this.state.stringMyConditions[this.state.thisField - 1] = stringField;			
			console.log(" stringMyConditions length " + this.state.stringMyConditions.length);
			console.log("field = " + field);
		}
		async handleFuelInput(event){
			this.setState({ newFuelAllowance: event.target.value });
//				console.log("fuel allowance = " + this.state.fuelAllowance);
		}
		
		async handleDistanceInput(event){
			this.setState({ newDistance: event.target.value });
		}
		
		async handleEntryFeeInput(event){
			this.setState({ setEntryFee: event.target.value });
		}

		async handlePrizeInput(event){
			this.setState({ setPrize: event.target.value });
		}
				
		async handleCooldownInput(event){
			this.setState({ setCooldown: event.target.value });
			console.log(event.target.value);
		}

		async handlePrestigeInput(event){
			this.setState({ setPrestige: event.target.value });
			console.log(event.target.value);
		}
		async resetDefaults(){
			this.setState({ setRaceType: "Standard", setEntryFee: 0, setPrize: 0, setCooldown: 22, setPrestige: 2 });

		}
		
		
		async loadStrategy(){
		//	this.state.stringMyStrategy = []; this.state.myStrategy = []
//			console.log ("print " + this.state.id);
	
			const loadedStrategy = this.state.globalAliensArray[this.state.id].strategy;

			for (let j = 0; j < 9; j++){  //loaded strategy is loaded from blockchain
				if (loadedStrategy[j] == 1){
					this.state.savedStrategy[j] = "Inertia Drift";  // saved strategy is what is displayed at helm by clicking LoadStrategy button
				} else if (loadedStrategy[j] == 2){
					this.state.savedStrategy[j] = "Impulse Engines";
				} else if (loadedStrategy[j] == 3){
					this.state.savedStrategy[j] = "Warp Drive";
				}  else if (loadedStrategy[j] == 4){
					this.state.savedStrategy[j] = "Hyper Space";
				} else if (loadedStrategy[j] == 5){
					this.state.savedStrategy[j] = "Shields";
				} else {
					this.state.savedStrategy[j] = "";
				}
			}
			this.state.savedFuelApplied = this.state.fuel;
			
//		console.log ("savedStrategy " + this.state.savedStrategy[0]);
		this.setState({});
		this.calculateEngine();
		}
		//--------------
		//this.state.fieldConditions
		//0 = All Clear
		//1 = Meteor Belt
		//2 = Space Pirates
		//3 = Pirates Radar
		//4 = Star Gravity Field
		//5 = hyperspace Wormhole
		//6 = Plasma Anomaly
		//--------------
		//this.state.strategy
		//1 = Inertia Drift
		//2 = Impulse Engines
		//3 = Warp Drive
		//4 = Hyper Drive
		//5 = Shields
		async calculateEngine(){
			let q;
			let l;
			let eFactor;
			let eArray = [];
			let fFactor;
			let fArray = [];
			let fieldConditionsArray = [];
			let fuelUsage;
//			console.log(" strategy conditions " + this.state.strategy);     // 513342511
//			console.log(" field conditions " + this.state.fieldConditions); // 12345012
//			console.log(" field conditions length " + this.state.fieldConditions.length);
			fieldConditionsArray = this.state.fieldConditions.toString().split("");
//			console.log(" field conditions array " + fieldConditionsArray[0]);
			for(let k = 0; k < 9; k++){
				let legDist = this.state.distance * 0.1;
				for( l = 0; l < this.state.strategy.length; l++){
					
					if(fieldConditionsArray[k] == "1"){  		//  if field conditions = all clear
						q =" all engines perform normal";      		// all engines normal for all strategies
						if(this.state.strategy[k] == "1"){ 			// drift = no fuel
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.DRIFT_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == "2"){  // impulse = 2 fuel burned, 2 X speed per K
							eFactor = window.Configs.IMPULSE_SPEED;
							fuelUsage = window.Configs.IMPULSE_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == "3"){  // warp = 4 fuel
							eFactor = window.Configs.WARP_SPEED;
							fuelUsage = window.Configs.WARP_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == "4"){  // hyper = 6 fuel
							eFactor = window.Configs.HYPER_SPEED;
							fuelUsage = window.Configs.HYPER_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == "5"){  // shields = 2 fuel
							eFactor = window.Configs.SHIELDS_SPEED;
							fuelUsage = 2;
							break;
						}

					} else if(fieldConditionsArray[k] == "2"){  // if field conditions = meteors
						if(k == 0){console.log(" strategy conditions111 " + " =l" + l + " " + this.state.strategy[l])}
						
						if(this.state.strategy[k] == 5){     // if shields are on
							q = " engine performs as impulse";		// engines perform as impulse
							eFactor = window.Configs.SHIELDS_SPEED;
							fuelUsage = window.Configs.SHIELDS_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 1){		// or else  drift engine
							q = " engine performs as drift";		// no shields means we drift
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.DRIFT_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 2){		// or else  impulse
							q = " engine performs as drift";		// no shields means we drift
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.IMPULSE_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 3){		// or else  warp
							q = " engine performs as drift";		// no shields means we drift
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.WARP_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 4){		// or else  hyper
							q = " engine performs as drift";		// no shields means we drift
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.HYPER_FUEL * legDist;
							break;
						} 
						
					} else if(fieldConditionsArray[k] == "3"){  //  if field conditions = pirates
					
						if(this.state.strategy[k] == 1){		// if engines are drift 
							q = " engine performs as drift && shields unharmed";// drift speed = no penalty
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.DRIFT_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 2){		// or else  impulse
							q = " engine performs as drift";		// shields take damage, more fuel usage
							eFactor = window.Configs.IMPULSE_SPEED;
							fuelUsage = (window.Configs.IMPULSE_FUEL + window.Configs.SHIELDS_FUEL) * legDist;
							break;
						} else if(this.state.strategy[k] == 3){		// or else  warp
							q = " engine performs as drift";		// shields take damage, more fuel usage
							eFactor = window.Configs.WARP_SPEED;
							fuelUsage = (window.Configs.SHIELDS_FUEL + window.Configs.WARP_FUEL) * legDist;
							break;
						} else if(this.state.strategy[k] == 4){		// or else  hyper
							q = " engine performs as drift";		// shields take damage, more fuel usage
							eFactor = window.Configs.HYPER_SPEED;
							fuelUsage = window.Configs.SHIELDS_FUEL + window.Configs.HYPER_FUEL;
							break;
						} else if(this.state.strategy[k] == 5){		// or else  shields
							q = " engine performs as drift";		// shields up impulse speed normal fuel + 1
							eFactor = window.Configs.SHIELDS_SPEED;
							fuelUsage = (window.Configs.SHIELDS_FUEL + (window.Configs.IMPULSE_FUEL / 2)) * legDist;
							break;
						}
						
					} else if(fieldConditionsArray[k] == "4"){  //  if field conditions = pirate radar
						if((this.state.strategy[k] == "0")||(this.state.strategy[k] == "1")){ // if strategy = drift or impulse
							q = " shields take damage 2";													// shields will take damage - or
							eFactor = window.Configs.DRIFT_SPEED;
							break;
						} else if (this.state.strategy[k] == "5"){								// if shields are on
							q  = " shields take half damage, impulse speed";					// shields take half damage and speed is impulse	
							eFactor = window.Configs.IMPULSE_SPEED;
							fuelUsage = ((window.Configs.SHIELDS_FUEL / 2) + window.Configs.IMPULSE_FUEL) * legDist;
							break;
						} else  if (this.state.strategy[k] == "3"){
							q = " engines perform as normal (warp )";
							eFactor = window.Configs.WARP_SPEED;
							fuelUsage = (window.Configs.WARP_FUEL + window.Configs.SHIELDS_FUEL) * legDist;
							break;
						} else  if (this.state.strategy[k] == "4"){
							q = " engines perform as normal  (hyper)"
							eFactor = window.Configs.HYPER_SPEED;
							fuelUsage = (window.Configs.HYPER_FUEL + window.Configs.SHIELDS_FUEL) * legDist;
							break;
						}

					} else if(fieldConditionsArray[k] == "5"){  //  if field conditions = star gravity
						if(this.state.strategy[k] == "3") {
							q = " engine performs 2x warp";			// warp engines best result speed x2 and use less fuel
							eFactor = window.Configs.WARP_SPEED * 2;
							fuelUsage = window.Configs.WARP_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 1) {	// drift use no fuel
							q = "enginers perform as drift";
							eFactor = window.Configs.DRIFT_SPEED;
							fuelUsage = window.Configs.DRIFT_FUEL * legDist;
							break;
						}else if(this.state.strategy[k] == 2){		// or else  impulse
							q = " engine performs as normal";		
							eFactor = window.Configs.IMPULSE_SPEED;
							fuelUsage = window.Configs.IMPULSE_FUEL * legDist;
							break;

						} else if(this.state.strategy[k] == 4){		// or else  hyper
							q = " engine performs as normal";		
							eFactor = window.Configs.HYPER_SPEED;
							fuelUsage = window.Configs.HYPER_FUEL * legDist;
							break;
						} else if(this.state.strategy[k] == 5){		// or else  shields
							q = " engine performs as normal";		// shields up impulse speed normal fuel + 1
							eFactor = window.Configs.SHIELDS_SPEED;
							fuelUsage = ((window.Configs.SHIELDS_FUEL / 2) + window.Configs.IMPULSE_FUEL) * legDist;
							break;
						}
						
					} else if(fieldConditionsArray[k] == "6"){  //  if field conditions = worm hole
						if(this.state.strategy[k] == "4") {
							q = " engine performs 2x hyper"; 		// hyper drive for max speed use less fuel
							eFactor = window.Configs.HYPER_SPEED * 2;
							fuelUsage = window.Configs.HYPER_FUEL * legDist;
							break;
						} else {
							q = "enginers perform as normal";
							if(this.state.strategy[k] == "1"){		// drift
								eFactor = window.Configs.DRIFT_SPEED;
								fuelUsage = window.Configs.DRIFT_FUEL * legDist;
								break;
							} else if(this.state.strategy[k] == "2"){	// impulse
								eFactor = window.Configs.IMPULSE_SPEED;
								fuelUsage = window.Configs.IMPULSE_FUEL * legDist;
								break;
							} else if(this.state.strategy[k] == "3"){	// warp
								eFactor = window.Configs.WARP_SPEED;
								fuelUsage = window.Configs.WARP_FUEL;
								break;
							} else if(this.state.strategy[k] == "5"){	// shields
								eFactor = window.Configs.SHIELDS_SPEED;
								fuelUsage = ((window.Configs.SHIELDS_FUEL / 2) + window.Configs.IMPULSE_FUEL) * legDist;
								break;
							}
						}

					} 
					
	//				console.log("strategy L = " + this.state.strategy[l]);
				}  //  console.log(k + " " + l + " log qqq = " + q );
	//			console.log(this.state.fieldConditions[k]);
				eArray.push(eFactor);
				fArray.push(fuelUsage);
	//			console.log ("fffuuueeelll = " + fuelUsage);
			
			}
			
//			console.log("say hello " + this.state.fieldConditions[0]);
//			console.log(" strategy hello " + this.state.strategy[0]);
//			console.log( "log q = " + q );
//			console.log("eArray = " + eArray);
//			console.log(" strategy conditions " + this.state.strategy);
//			console.log(" field conditions " + this.state.fieldConditions); //12345012
			this.setState({ eArray: eArray });
			this.setState({ fArray: fArray });
//				console.log("mmmm " + this.state.eArray);
		}
		
		
		truncWinner(winner)	{  // not currently used, seems to bleed into this.state.account
			let a = (winner.slice(0, -36));
			let b = "...";
			let c = (winner.slice(-4));
			let thisWinner = a + b + c;
			return thisWinner;
		}
		
	trackPause(){
		this.setState ({ audioClass: "audioPause" });
		if(this.state.track ==1){
			this.state.sound1.pause();
		}else if (this.state.track == 2){
			this.state.sound2.pause();
		} else if (this.state.track == 3){
			this.state.sound3.pause();
			this.setState({ trackText: "Catalyst" });
		} else if (this.state.track == 4){
			this.state.sound4.pause();
			this.setState({ trackText: "Clarion" });
		} else if (this.state.track == 5){
			this.state.sound5.pause();
			this.setState({ trackText: "Helios" });
		} else if (this.state.track == 6){
			this.state.sound6.pause();
			this.setState({ trackText: "Pathfinder" });
		} else if (this.state.track == 7){
			this.state.sound7.pause();
			this.setState({ trackText: "Signal To Noise" });
		} else if (this.state.track == 8){
			this.state.sound8.pause();
			this.setState({ trackText: "Solace" });
		} else if (this.state.track == 9){
			this.state.sound9.pause();
			this.setState({ trackText: "Wayfarer" });
		}
	}

	trackChangeStop(){
		this.setState ({ audioClass: "audioStop" });
		if(this.state.track ==1){
			this.state.sound1.stop();
		}else if (this.state.track == 2){
			this.state.sound2.stop();
		} else if (this.state.track == 3){
			this.state.sound3.stop();
			this.setState({ trackText: "Catalyst" });
		} else if (this.state.track == 4){
			this.state.sound4.stop();
			this.setState({ trackText: "Clarion" });
		} else if (this.state.track == 5){
			this.state.sound5.stop();
			this.setState({ trackText: "Helios" });
		} else if (this.state.track == 6){
			this.state.sound6.stop();
			this.setState({ trackText: "Pathfinder" });
		} else if (this.state.track == 7){
			this.state.sound7.stop();
			this.setState({ trackText: "Signal To Noise" });
		} else if (this.state.track == 8){
			this.state.sound8.stop();
			this.setState({ trackText: "Solace" });
		} else if (this.state.track == 9){
			this.state.sound9.stop();
			this.setState({ trackText: "Wayfarer" });
		}
	}
	trackChangePlay(){
		this.setState ({ audioClass: "audio-gif" });
		if(this.state.track ==1){
			this.state.sound1.play();
			this.setState({ trackText: "Terminus" }); 
		} else if (this.state.track == 2){
			this.state.sound2.play();
			this.setState({ trackText: "The Miracle Of Flight" });
		} else if (this.state.track == 3){
			this.state.sound3.play();
			this.setState({ trackText: "Catalyst" });
		} else if (this.state.track == 4){
			this.state.sound4.play();
			this.setState({ trackText: "Clarion" });
		} else if (this.state.track == 5){
			this.state.sound5.play();
			this.setState({ trackText: "Helios" });
		} else if (this.state.track == 6){
			this.state.sound6.play();
			this.setState({ trackText: "Pathfinder" });
		} else if (this.state.track == 7){
			this.state.sound7.play();
			this.setState({ trackText: "Signal To Noise" });
		} else if (this.state.track == 8){
			this.state.sound8.play();
			this.setState({ trackText: "Solace" });
		} else if (this.state.track == 9){
			this.state.sound9.play();
			this.setState({ trackText: "Wayfarer" });
		}
	}
	makeSomeNote(){
		console.log("Some note");
	}
	changeTrackUp(){
		if(this.state.track != 9){
			
			console.log("Changing track up");
			this.trackChangeStop()
			this.state.track++;
			this.trackChangePlay()
		}
	}
	changeTrackDown(){
		if(this.state.track != 1){
			
			console.log("Changing track down");
			this.trackChangeStop()
			this.state.track--;
			this.trackChangePlay()
		}
	}
	
	sayWhat(){
		Howler.volume(0.5);
	}
	
	calculateTime(cool){
		let coolTime = cool - (Date.now()/1000);
		if (coolTime > 0){
			coolTime = Math.round(coolTime)/3600;
			return coolTime.toFixed(2);
		} else {
			return 0;
		}
	}
	showChamps(id){
		let champs = 0;
		for(var i = 0; i < this.state.myWinnersArray.length; i++){
			if(this.state.myWinnersArray[i][2] == id) {
				if(this.state.myWinnersArray[i][6] == true){
				champs++;
				}
				
			}
//			console.log("showing champs " + champs);
			let theString = "Championships: " + champs;
			if (champs > 0){
			return theString;
			}
		
		}
	}

	addMyGem(theGem, theInt){
		console.log("the gem = " + theGem);
		console.log("gem selected 3nd = " + this.state.gemSelected);
		console.log("has 3 gems 1= " + this.state.has3Gems);
		console.log("gem balance = " + this.state.gemBalance[this.state.account, 0]);
		
		var thisGemBalance = this.state.gemBalance[this.state.account, theInt] ;
		this.setState({OptionalThirdGem: thisGemBalance });
		if(thisGemBalance > 1){
			if(!this.state.gemSelected){
				let gemTradeItems = this.state.gemTradeItems;
				gemTradeItems.push(theGem);
				gemTradeItems.push(theGem);
				console.log("the gem array = " + gemTradeItems[0]);
				this.setState({ gemTradeItems });
				this.setState({ thisGemTrade: theGem });
				this.setState({gemSelected: true });
	//			console.log(JSON.stringify(this.state.gemTradeItems));
			} 
		}
	}

	addThirdGem(){
		console.log("gem selected 3rd = " + this.state.gemSelected);
		console.log("has 3 gems 2= " + this.state.has3Gems);
		console.log("OptionalThirdGem = " + this.state.OptionalThirdGem );
		let gem3 = this.state.gemTradeItems[0];
		if(this.state.OptionalThirdGem > 2){
			if(!this.state.has3Gems){
				if(this.state.gemSelected){
					let gemTradeItems = this.state.gemTradeItems;
					gemTradeItems.push(this.state.gemTradeItems[0]);
					console.log("third gem = " + this.state.gemTradeItems[0]);
					this.setState({ gemTradeItems });
					this.setState({ has3Gems: true });
				}
			}
		}
	}

	resetChoices(){
		this.setState({gemTradeItems: []});
		this.setState({gemSelected: false });
		this.setState({ has3Gems: false });
		this.setState({ OptionalThirdGem: 0 });
	}
	useGems(id){
		if(this.state.entryFee == 0){
			this.setState({ Open: true }); this.setState({ text:  "No entry fee required" });
		}else{
			
			this.joinRace(id, true)
			this.spendGem();
		}
	}
	
	gemToAllStarters() {
		let racerRewardArray = [];
		for (let q = 0; q < this.state.racerReward.length; q++){
			racerRewardArray.push(this.state.racerReward[q]);
		}
		this.loadAirdrop(racerRewardArray, 1);
		console.log("racerReward = " + racerRewardArray);
	}
	
	showAllFinishers() { // not currently used
		let theFinisher;
		for (let u = 0; u < this.state.finisherOwners.length; u++){
			theFinisher = this.state.finisherOwners[u];
			console.log(this.state.finisherOwners);
			this.directAirdrop(theFinisher, 4, 5);
		}
	}

//	showPrestige(){
//		 return this.state.allPrestige;
//	}
		
	needsPlot(isRacing, strategy){
		if ((isRacing== true) && (strategy == 1)){
			 let needsPlotNow = <h4 style={{color: "red"}}>No Strategy Set</h4>
			return needsPlotNow;
		}
	}
	 
////////////////////////////////////////////////****************************************************RENDER FROM HERE ***************************************//////////////////////////////////////////////

render() {
	

	    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason == 'clickaway') {
          return;
        }
        this.setState({ Open: false });
		this.setState({ Open1: false });
		};
//const text = "Wrong network. Change Metamask to Ganache Network and refresh page.";


		function Alert(props: AlertProps) {
			return <MuiAlert elevation={6} variant="filled" {...props} />;
		}

//		const sound2 = new Howl({
//			src: ["http://localhost/audio/sb_solace.mp3"],
//			html5: true
//	  });	
	  
		let resetValues = <Button  color="orange" onClick={() => {this.resetDefaults()} }>Reset defaults</Button>
	//	let checkNameButton = <Button  color="green" onClick={() => {this.checkName()} }>Check Name Availability</Button>
		let claimNameButton = "";	
		let approvalText = "";
		if(this.state.nameApproved){
			if(this.state.registeredUserHandle != this.state.account.slice(0, -34) + "..." ){
				console.log("user " + this.state.registeredUserHandle + " account " + this.state.account);
				claimNameButton = <Button  color="green" onClick={() => {this.changeName(this.state.myNameCheck, this.state.registeredUserHandle)} }>Change Name</Button>
				approvalText = window.Configs.NAME_APPROVAL_TEXT;
			} else{
				claimNameButton = <Button  color="green" onClick={() => {this.claimName(this.state.myNameCheck)} }>Register Selected Name</Button>
				approvalText = window.Configs.NAME_APPROVAL_TEXT;
			}
		} else {
			claimNameButton = "";
		}
		
		let claimUserName = "";
		if(this.state.registeredUserHandle != this.state.account.slice(0, -34) + "..." ){
			claimUserName = "Change Name"
		} else{
			claimUserName = "Claim Name"
		}
/*		
		let claimUserNameButton;
		let claimButtonText;
		if (this.state.account == this.state.registeredUserHandle) {
			claimButtonText = "Claim User Name";
			claimUserNameButton = <Button  color="green" onClick={() => {this.showModalRegisterName(this.state.account, this.state.networkId)} }>{claimButtonText}</Button>
		} else {
			claimButtonText = "Change User Name";
			claimUserNameButton = <Button color="orange" onClick={() => {this.showModalRegisterName(this.state.account, this.state.networkId)} }>{claimButtonText}</Button>;		  
		} 
*/
		let thisLeg;
		let thisField;

			let fields = [];
			let fieldArray = [];
//		console.log("Field conditions = " + this.state.fieldConditions);
	//	const tempFieldConditions = this.state.fieldConditions;
//	const tempFieldConditions = 12113141;
		 fieldArray = this.state.fieldConditions.toString().split("");
//		console.log("Field Array = " + fieldArray);
		for( let i = 0; i < fieldArray.length; i++){
			if (fieldArray[i] == "1"){
				fields.push("All Clear");
			}else if (fieldArray[i] == "2"){
				fields.push("Meteor Belt");
			} else if (fieldArray[i] == "3"){
				fields.push("Space Pirates");
			} else if (fieldArray[i] == "4"){
				fields.push("Pirates Radar");
			} else if (fieldArray[i] == "5"){
				fields.push("Star Gravity Field");
			} else if (fieldArray[i] == "6"){
				fields.push("WormHole");
			} else if (fieldArray[i] == "7"){
				fields.push("Plasma Anomaly");
			} 
		} //console.log ("kkkkkkkkkkkkkkkkkkkkkkkkkkkk" + fields[4]);	
		
		let startRaceButton;
		let startResult;
		
		if (this.state.account == "0x7Fb34562fC04e875aA9d9a4Fb04E6db99C2b4c6C"){ // SPARK // only allow owner to start and end races 
	//	if (this.state.account == "0x7B420EBd692CC83443a215c6BEaF642CbEab2f97"){ // Ganache // only allow owner to start and end races
			if (this.state.hasStarted) {
			console.log(this.state.hasPostedWinner + "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");	
				if (!this.state.hasPostedWinner){
					startRaceButton = <Button loading onClick={() => {alert("Post winners first")} }>{startResult}</Button>;
				}else{
					startResult = "End Race";
					startRaceButton = <Button negative onClick={() => {this.endRace(); this.loadAirdrop(this.state.winnerOwner, 5);this.hideModal();} }>{startResult}</Button>
				}
			} else {
				startResult = "Start Race - wait for entrants";
				if(this.state.raceReady){
			  startRaceButton = <Button positive onClick={() => {this.startRace(); this.hideModal(); this.setState({raceStarted: true }); this.gemToAllStarters() } }>{startResult}</Button>;
								<h3> Wait for entrants </h3>;
				}else{
					startRaceButton = <Button loading onClick={() => {alert("Conditions not layed in")} }>{startResult}</Button>;
					
				}
			} 
		} else {
			startRaceButton = "";
		}
				
		let entered;
		let TheHelm;
			if(this.state.isRacing == true){
				 entered = "Have entered race " + this.state.raceNumber;
//				 console.log( "is racing 1a " + this.state.isRacing.toString());
				if(this.state.strategy == 1){
					TheHelm = 
					<>
					<Grid  item xs={12} onClick= {() => {this.showModalPlot(this.state.id, this.state.name, this.state.level, this.state.fuel); this.hideModal(); this.loadStrategy()}}style={{cursor:'pointer'}} >
								<styles.ButtonContainer >
									<h4 style={{color: "yellow"}}>Plot Course</h4>
								</styles.ButtonContainer>
					</Grid>
					<Grid  item xs={12}>You have yet to plot your course</Grid>
					</>;
				} else {
					TheHelm = 
					<Grid  item xs={12} onClick= {() => {this.showModalHelm(this.state.id, this.state.name, this.state.level, this.state.fuel); this.loadStrategy(); this.hideModal()}}style={{cursor:'pointer'}} >
						<styles.ButtonContainerAnim >
							<h2>Helm</h2>
						</styles.ButtonContainerAnim>	
					</Grid>
				}					 	 
			} else {
			//	 console.log( "is racing not? " + this.state.isRacing);
				 if(this.state.isRacing != undefined){
				 console.log( "is racing racing " + this.state.isRacing.toString());
				 }
				if(this.state.raceReady == true){
					 entered = "Have not entered race " + this.state.raceNumber;
				} else{
				 entered = "Have not entered race " + (Number.parseInt(this.state.raceNumber) + 1);
//				  console.log( "is racing 2 " + this.state.isRacing);
				}
			}
			if (this.state.hasStarted == true){
				if(this.state.isRacing == true){
					entered = "You are currently racing";
				} else {
					entered = "Cannot enter while race is active"
				}
			}

	//	this.setState({ entered });
		let currentRace;
		let startingTime;
		if(((this.state.hasEnded)&&(!this.state.hasStarted))&&(this.state.raceReady)){
			currentRace = "Race " + + this.state.raceNumber + " is open for entrants."
			startingTime = "Start Time: Soon."
		}else
			if((this.state.hasEnded)&&(!this.state.hasStarted)){
				 currentRace = "Race " + + this.state.raceNumber + " Has ended.";
				 startingTime = "Please wait for next race to open for entrants.";
			} else if((!this.state.hasEnded)&&(this.state.hasStarted)){
				 currentRace = "Race " + this.state.raceNumber + " is active.";
				 startingTime = "Start Time: " + this.state.formattedStartTime;
			} else if (this.state.hasEnded) {
			}
			
			
//			console.log(" this race number = " + this.state.raceNumber);
		let plotCourseButton;
//		console.log("entered = " + entered);
		if(this.state.isRacing){
			plotCourseButton = <Button positive onClick={(e) => {this.showModalPlot(this.state.id, this.state.name, this.state.level, this.state.fuel, e); this.hideModal()}} >Plot Course</Button>
		} else {
			plotCourseButton = ""
		}
		
		let someTestResult= "some test result";
		let enterRaceButton;
		let enterRaceText;
		let currentRaceNumber;
//		console.log("allowed? " + this.state.isAllowedToEnter);

		if(this.state.hasStarted) {
			enterRaceButton = ""
			currentRaceNumber = this.state.raceNumber;
		} else{
			if(this.state.raceReady == true){
				currentRaceNumber = this.state.raceNumber;
			} else {
				currentRaceNumber = (Number.parseInt(this.state.raceNumber) + 1);
			}
			if (this.state.isRacing == false) {
				if(!this.state.raceReady){
//					console.log("really race ready? " + this.state.raceReady);
					enterRaceText = "Race " + (Number.parseInt(this.state.raceNumber) + 1) + " not ready";
					enterRaceButton = <div className=" buttonContainer enterRaceButtonContainer" >
									<h4>{enterRaceText}</h4>
								</div>
				} else{
					
					if(this.state.isAllowedToEnter){
						enterRaceText = "Enter Race " + this.state.raceNumber;

						enterRaceButton = <><Grid  item xs={12} onClick= {() => {this.joinRace(this.state.id, false); this.hideModal(); this.setState ({ savedFuelApplied: "" })} }style={{cursor:'pointer'}} >
										<div className=" buttonContainer enterRaceButtonContainer" >
											<h4>{enterRaceText}</h4>
										</div>
									</Grid>	<Grid
								item xs={12} onClick= {() => {
									if (this.state.entryFee != 0){
										this.useGems(this.state.id); this.hideModal(); this.setState ({ savedFuelApplied: "" })
									} else {
										this.setState({ Open: true }); this.setState({ text:  "No entry fee required" }); 
									}
								}}style={{cursor:'pointer'}} >
										<div className=" buttonContainer enterRaceButtonContainer" >
											<h4>Use Gems</h4>
										</div>
									</Grid></>
									
					}else{	enterRaceButton = <><p></p><h3 style={{color: "red"}}>Cooling Down</h3></>;}
				}				
			
			} else {
				enterRaceText = "Remove from Race";
				enterRaceButton = <Grid  item xs={12} onClick= {() => {this.unjoinRace(this.state.id); this.hideModal()} }style={{cursor:'pointer'}} >
								<div className=" buttonContainer enterRaceButtonContainer" >
									<h4>{enterRaceText}</h4>
								</div>
							</Grid>
			} 
		}
//console.log("is allowed to enter " + this.state.isAllowedToEnter);
		
		
		let inertiaButton = <styles.PlotEngineButton  className="trackDown" onClick={() => {this.setEngine(1); this.setState({})} }>Inertia Drift</styles.PlotEngineButton>;
		let impulseButton = <styles.PlotEngineButton className="trackDown" onClick={() => {this.setEngine(2); this.setState({})} }>Impulse</styles.PlotEngineButton>;
		let warpButton = <styles.PlotEngineButton  className="trackDown" onClick={() => {this.setEngine(3); this.setState({})} }>Warp Drive</styles.PlotEngineButton>;
		let hyperButton = <styles.PlotEngineButton  className="trackDown" onClick={() => {this.setEngine(4); this.setState({})} }>Hyper Space</styles.PlotEngineButton>;
		let shieldsButton = <styles.PlotEngineButton  className="trackDown" onClick={() => {this.setEngine(5); this.setState({})} }>Shields</styles.PlotEngineButton>;
		
		let lockButton
	//	if((this.state.enginesAdded >= 9)&&(this.state.stringMyStrategy.length == 9)){
			for(let p = 0; p < window.Configs.FIELDS; p++){  // 9 is the number of fields (legs)
				if (((this.state.stringMyStrategy[p] == undefined) || (this.state.savedFuelApplied == undefined)) || (this.state.savedFuelApplied == 0)){
		//			lockButton = <Button  onClick={() => {alert("Strategy not complete") }}>Lay In Course</Button>;
					lockButton = <styles.LayInCourseButton  onClick={() => {alert("Strategy not complete")} }>Lay In Course</styles.LayInCourseButton>
					break;
				}
		//		else{	lockButton = <Button positive onClick={() => {this.lockCourse(); this.state.stringMyStrategy = []; this.state.myStrategy = []} }>Lock Course</Button>;}
				else{ lockButton = <styles.LockCourseButton onClick={() => {this.lockCourse(); this.state.stringMyStrategy = []; this.state.myStrategy = []} }>Lock Course</styles.LockCourseButton>;}
//				console.log("kkkkkkkkkkkkkksssssssssssssllllllllllllllaaaaaaaa " + this.state.savedFuelApplied);
			//	}
		//	}else {}
		}

		
		let leg1 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "1" })} }>Leg 1</styles.PlotButton>;	  // setting states of the strategy of each player
		let leg2 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "2" })} }>Leg 2</styles.PlotButton>;
		let leg3 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "3" })} }>Leg 3</styles.PlotButton>;
		let leg4 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "4" })} }>Leg 4</styles.PlotButton>;
		let leg5 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "5" })} }>Leg 5</styles.PlotButton>;	
		let leg6 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "6" })} }>Leg 6</styles.PlotButton>;
		let leg7 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "7" })} }>Leg 7</styles.PlotButton>;
		let leg8 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "8" })} }>Leg 8</styles.PlotButton>;
		let leg9 = <styles.PlotButton  className="trackUp" onClick={() => {this.setState({ thisLeg: "9" })} }>Leg 9</styles.PlotButton>;
		let FinalSprint = <styles.PlotButton  className="trackUp" onClick={() => {this.state.finalFuelBool = true; this.sprintForm(); } }>Final%</styles.PlotButton>;
	
//	let showStrategy = <Button positive onClick={() => {this.loadStrategy(); this.state.stringMyStrategy = []; this.state.myStrategy = []} }>Show Strategy</Button>;
	
		let AllClear = <div className="buttonContainer"  onClick={() => {this.setFieldConditions(1); this.setState({})} }>All Clear</div>;   // each button inputs a strategy engine type into the leg selected from above
		let Meteors = <div className="buttonContainer" onClick={() => {this.setFieldConditions(2); this.setState({})} }>Meteors</div>;
		let Pirates = <div className="buttonContainer" onClick={() => {this.setFieldConditions(3); this.setState({})} }>Pirates</div>;
		let PirateRadar = <div className="buttonContainer" onClick={() => {this.setFieldConditions(4); this.setState({})} }>Pirate Radar</div>;
		let GravityStar = <div className="buttonContainer" onClick={() => {this.setFieldConditions(5); this.setState({})} }>Gravity Star</div>;
		let WormHole = <div className="buttonContainer" onClick={() => {this.setFieldConditions(6); this.setState({})} }>Worm Hole</div>;
		let PlasmaField = <div className="buttonContainer" onClick={() => {this.setFieldConditions(7); this.setState({})} }>Plasma Field</div>;

			
		let field1 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "1" })} }>1</div>;	// setting states of the field by the race controller
		let field2 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "2" })} }>2</div>;
		let field3 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "3" })} }>3</div>;
		let field4 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "4" })} }>4</div>;
		let field5 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "5" })} }>5</div>;	
		let field6 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "6" })} }>6</div>;
		let field7 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "7" })} }>7</div>;
		let field8 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "8" })} }>8</div>;
		let field9 = <div className="buttonNarrow"  onClick={() => {this.setState({ thisField: "9" })} }>9</div>;
		
		let Standard = <div className="buttonContainer"  onClick={() => { this.setState({ setRaceType: "Standard" }) }}> Standard</div>;
		let Beginners = <div className="buttonContainer"  onClick={() => { this.setState({ setRaceType: "Beginners" }) }}> Beginners</div>;
		let Maiden = <div className="buttonContainer"  onClick={() => { this.setState({ setRaceType: "Maiden" }) }}> Maiden</div>;
		let Featured = <div className="buttonContainer"  onClick={() => { this.setState({ setRaceType: "Featured" }) }}> Featured</div>;
		let Championship = <div className="buttonContainer"  onClick={() => { this.setState({ setRaceType: "Championship" }) }}> Championship</div>;

		let lockFieldButton;
		if(((this.state.fieldAdded >= 9)&&(this.state.stringMyConditions.length == 9))&&((this.state.newDistance != 0)) && (this.state.newFuelAllowance != "0" )){
//			console.log("lockfield dist = " + this.state.newDistance);
//			console.log("lockfield fuel = " + this.state.newFuelAllowance);
		 lockFieldButton = <Button positive onClick={() => {this.lockField(); this.state.stringMyField = []; this.state.myConditions = []} }>Lock Conditions</Button>;
		}else {lockFieldButton = <Button  onClick={() => {alert("Field Conditions not complete " + this.state.stringMyConditions.length) }}>Lay In Conditions</Button>;}
		
		
		
/*		
		let myEngine = this.state.engine;
	//	let mySpeed = 0;
	function setEngineColor(leg){
		
		console.log("state of engine " + myEngine);
		console.log("leg = " + leg);
	//	let legControl = this.state.myStrategy[this.state.thisLeg - 1];
		if(myEngine === 1){
		//	myEngine = "Inertia Drift";
			console.log("clicked " + myEngine);
			inertiaButton = <Button color='teal' onClick={() => {alert("Engines already set to Drift")} }>Inertia Drift</Button>;		
		} else if(leg=== 2){
	//		myEngine = "Impulse Engines";
			impulseButton = <Button color='teal' onClick={() => {alert("Engines already set to Impulse")} }>Impulse Engines</Button>;
		} else if(leg === 3){
	//		myEngine = "Warp Drive";
			warpButton = <Button color='teal'  onClick={() => {alert("Engines already set to Warp Drive")} }>Warp Drive</Button>;
		} else if(leg === 4){
	//		myEngine = "Hyper Space";
			hyperButton = <Button color='teal'  onClick={() => {alert("Engines already set to Hyper Space")} }>Hyper Space</Button>;
		}
	}
*/





//		console.log("finding strategy " + this.state.strategy);
		let splitStrategy = [];
		splitStrategy = this.state.strategy.toString().split("");
//		console.log("firstLegEngine1 " + splitStrategy[0]);
//		console.log("firstLegEngine1 " + splitStrategy)
		let firstLeg;
//		let firstLegEngine = Number(splitStrategy[0]);
		let firstLegEngine = this.state.eArray[0];
		let firstLegFuel = this.state.fArray[0];
//		console.log("firstLeg fuel " + this.state.fArray[0])
//		console.log("firstLegEngine1 " + this.state.eArray[0])
//		console.log("firstLegEngine2 " + firstLegEngine);
		let secondLeg;
//		let secondLegEngine = Number(splitStrategy[1]);
		let secondLegEngine = this.state.eArray[1];
		let secondLegFuel = this.state.fArray[1];
		let thirdLeg;
//		let thirdLegEngine = Number(splitStrategy[2]);
		let thirdLegEngine = this.state.eArray[2];
		let thirdLegFuel = this.state.fArray[2];
		let fourthLeg;
//		let fourthLegEngine = Number(splitStrategy[3]);	
		let fourthLegEngine = this.state.eArray[3];
		let fourthLegFuel = this.state.fArray[3];
		let fifthLeg;
//		let fifthLegEngine = Number(splitStrategy[4]);	
		let fifthLegEngine = this.state.eArray[4];
		let fifthLegFuel = this.state.fArray[4];
		let sixthLeg;
//		let sixthLegEngine = Number(splitStrategy[5]);	
		let sixthLegEngine = this.state.eArray[5];
		let sixthLegFuel = this.state.fArray[5];
		let seventhLeg;
//		let seventhLegEngine = Number(splitStrategy[6]);	
		let seventhLegEngine = this.state.eArray[6];
		let seventhLegFuel = this.state.fArray[6];
		let eighthLeg;
//		let eighthLegEngine = Number(splitStrategy[7]);	
		let eighthLegEngine = this.state.eArray[7];
		let eighthLegFuel = this.state.fArray[7];
		let ninthLeg;
//		let ninthLegEngine = Number(splitStrategy[8]);	
		let ninthLegEngine = this.state.eArray[8];
		let ninthLegFuel = this.state.fArray[8];	
		let finalLeg;
		let firstLegTime;
		let secondLegTime;
		let thirdLegTime;
		let fourthLegTime;	
		let fifthLegTime;
		let sixthLegTime;
		let seventhLegTime;
		let eighthLegTime;	
		let ninthLegTime;	
		let finalLegTime; 		
		let totalTimeTaken;
		let firstLegDist = this.state.distance * 0.1;
		let secondLegDist = this.state.distance * 0.1;
		let thirdLegDist = this.state.distance * 0.1;
		let fourthLegDist = this.state.distance * 0.1;
		let fifthLegDist = this.state.distance * 0.1;
		let sixthLegDist = this.state.distance * 0.1;
		let seventhLegDist = this.state.distance * 0.1;
		let eighthLegDist = this.state.distance * 0.1;	
		let ninthLegDist = this.state.distance * 0.1;		
		let finalSprintDist = this.state.distance * 0.1;
		
		let timeRacing = ( Math.round(Date.now()/1000) * Number(this.state.hasStarted) - this.state.startTime);
		let totalFuelUsed = 0;
		for (let n = 0; n < this.state.fArray.length; n++){
			totalFuelUsed = totalFuelUsed + this.state.fArray[n];
//			console.log(n + " fArray n = " + this.state.fArray[n]);
		}
//		this.setState({ totalFuelUsed });
		this.state.totalFuelUsed = totalFuelUsed;
//		console.log("Total fuel used = " + totalFuelUsed);
//		console.log("State Total fuel used = " + this.state.totalFuelUsed);

//		calculateEngine(1, 2);

		let lowFuelWarning = false;
		let lowFuelWarning1 = false;
		let lowFuelWarning2 = false;
		let lowFuelWarning3 = false;
		let lowFuelWarning4 = false;
		let lowFuelWarning5 = false;
		let lowFuelWarning6 = false;
		let lowFuelWarning7 = false;
		let lowFuelWarning8 = false;
		let lowFuelWarning9 = false;
//		let timeTakenFirst = firstLegDist / this.state.engine
//		let fuelRemaining;
		
		let timeTakenFirst;
		let fuelForFirst = this.state.fuelAllowance;
//		fuelRemaining = fuelForFirst;
		if(fuelForFirst >= firstLegFuel){
			timeTakenFirst = firstLegDist / firstLegEngine;
			lowFuelWarning1 = false;
//			console.log(" first fuel true = " + fuelForFirst);
//			console.log(" first leg fuel true = " + firstLegFuel);			
		} else {
			timeTakenFirst = firstLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning1 = true;
//			console.log(" first fuel false = " + fuelForFirst);
//			console.log(" first leg fuel false = " + firstLegFuel);
			}
		
		let timeTakenSecond;
		let fuelForSecond = fuelForFirst - firstLegFuel;
		if (fuelForSecond < 1) {fuelForSecond = 0;}
		if(fuelForSecond >= secondLegFuel){
			timeTakenSecond = secondLegDist / secondLegEngine;
			lowFuelWarning2 = false;
//			console.log(" second fuel true = " + fuelForSecond);
//			console.log(" second leg fuel true = " + secondLegFuel);			
		} else {
			timeTakenSecond = secondLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning2 = true;
//			console.log(" second fuel false = " + fuelForSecond);
//			console.log(" second leg fuel false = " + secondLegFuel);
			}
		
		let timeTakenThird;
		let fuelForThird = fuelForSecond - secondLegFuel;
		if (fuelForThird < 1) {fuelForThird = 0;}
		if(fuelForThird >= thirdLegFuel){
			timeTakenThird = thirdLegDist / thirdLegEngine;
			lowFuelWarning3 = false;
		} else {
			timeTakenThird = thirdLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning3 = true;
			}
		
		let timeTakenFourth;
		let fuelForFourth = fuelForThird - thirdLegFuel;
		if (fuelForFourth < 1) {fuelForFourth = 0;}
		if(fuelForFourth >= fourthLegFuel){
			timeTakenFourth = fourthLegDist / fourthLegEngine;
			lowFuelWarning4 = false;
		} else {
			timeTakenFourth = fourthLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning4 = true;
			}
				
		let timeTakenFifth;
		let fuelForFifth = fuelForFourth - fourthLegFuel;
		if (fuelForFifth < 1) {fuelForFifth = 0;}
		if(fuelForFifth >= fifthLegFuel){
			timeTakenFifth = fifthLegDist / fifthLegEngine;
			lowFuelWarning5 = false;
//			console.log(" fifth fuel true = " + fuelForFifth);
//			console.log(" fifth leg fuel true = " + fifthLegFuel);			
		} else {
			timeTakenFifth = fifthLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning5 = true;
//			console.log(" fifth fuel false = " + fuelForFifth);
//			console.log(" fifth leg fuel false = " + fifthLegFuel);
			}
		
		let timeTakenSixth;
		let fuelForSixth = fuelForFifth - fifthLegFuel;
		if (fuelForSixth < 1) {fuelForSixth = 0;}
		if(fuelForSixth >= sixthLegFuel){
			timeTakenSixth = sixthLegDist / sixthLegEngine;
			lowFuelWarning6 = false;
		} else {
			timeTakenSixth = sixthLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning6 = true;
			}
		
		let timeTakenSeventh;
		let fuelForSeventh = fuelForSixth - sixthLegFuel;
		if (fuelForSeventh < 1) {fuelForSeventh = 0;}
		if(fuelForSeventh >= seventhLegFuel){
			timeTakenSeventh = seventhLegDist / seventhLegEngine;
			lowFuelWarning7 = false;
		} else {
			timeTakenSeventh = seventhLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning7 = true;
			}
		
		let timeTakenEighth;	
		let fuelForEighth = fuelForSeventh - seventhLegFuel;
		if (fuelForEighth < 1) {fuelForEighth = 0;}
		if(fuelForEighth >= eighthLegFuel){
			timeTakenEighth = eighthLegDist / eighthLegEngine;
			lowFuelWarning8 = false;
//			console.log(" eighth fuel true = " + fuelForEighth);
//			console.log(" eighth leg fuel true = " + eighthLegFuel);	
		} else {
			timeTakenEighth = eighthLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning8 = true;
//			console.log(" eighth fuel false = " + fuelForEighth);
//			console.log(" eighth leg fuel false = " + eighthLegFuel);
			}
		
		let timeTakenNinth;
		let fuelForNinth = fuelForEighth - eighthLegFuel;
		if (fuelForNinth < 1) {fuelForNinth = 0;}
		if(fuelForNinth >= ninthLegFuel){
			timeTakenNinth = ninthLegDist / ninthLegEngine;
			lowFuelWarning9 = false;
		} else {
			timeTakenNinth = ninthLegDist / window.Configs.DRIFT_SPEED;
			lowFuelWarning9 = true;
		}
		
	
		
		let fuelForFinal = fuelForNinth - ninthLegFuel;
		if (fuelForFinal < 1) {fuelForFinal = 0;}
		
		let timeTakenSoFar;		
//console.log("timeTakenFirst " + timeTakenFirst);
//console.log("firstLegDist " + firstLegDist);
//console.log("firstLegEnginee " + firstLegEngine);
//console.log("timeTakenSecond " + timeTakenSecond);
//console.log("secondLegDist " + secondLegDist);
//console.log("secondLegEngine " + secondLegEngine);

		
		if (timeRacing > timeTakenFirst) {
			firstLegTime = timeTakenFirst;
			firstLeg = "Complete";
		} else {	
			firstLeg = "Incomplete";
		}
		
		if ((firstLeg == "Complete") && (timeRacing - timeTakenFirst > timeTakenSecond)) {
			secondLeg = "Complete";		
			secondLegTime = timeTakenSecond;			
		} else {	
			secondLeg = "Incomplete";
		}
		let timeSecond = timeTakenFirst + timeTakenSecond;
		if ((secondLeg == "Complete") && (timeRacing - timeSecond > timeTakenThird)) {
			thirdLeg = "Complete";			
			thirdLegTime = timeTakenThird;
		} else {	
			thirdLeg = "Incomplete";
		}
		let timeThird = timeSecond + timeTakenThird;
		if ((thirdLeg == "Complete") && (timeRacing - timeThird > timeTakenFourth)) {
			fourthLeg = "Complete";			
			fourthLegTime = timeTakenFourth;			
		} else {	
			fourthLeg = "Incomplete";
		}
		
		let timeFourth = timeThird + timeTakenFourth;
		if ((fourthLeg == "Complete") && (timeRacing - timeFourth > timeTakenFifth)) {
			fifthLeg = "Complete";			
			fifthLegTime = timeTakenFifth;			
		} else {	
			fifthLeg = "Incomplete";
		}
		let timeFifth = timeFourth + timeTakenFifth;
		if ((fifthLeg == "Complete") && (timeRacing - timeFifth > timeTakenSixth)) {
			sixthLeg = "Complete";			
			sixthLegTime = timeTakenSixth;			
		} else {	
			sixthLeg = "Incomplete";
		}
		let timeSixth = timeFifth + timeTakenSixth;
		if ((sixthLeg == "Complete") && (timeRacing - timeSixth > timeTakenSeventh)) {
			seventhLeg = "Complete";			
			seventhLegTime = timeTakenSeventh;			
		} else {	
			seventhLeg = "Incomplete";
		}
		let timeSeventh = timeSixth + timeTakenSeventh;
		if ((seventhLeg == "Complete") && (timeRacing - timeSeventh > timeTakenEighth)) {
			eighthLeg = "Complete";
			
			eighthLegTime = timeTakenEighth;			
		} else {	
			eighthLeg = "Incomplete";
		}
		
		let timeEighth = timeSeventh + timeTakenEighth;
		if ((eighthLeg == "Complete") && (timeRacing - timeEighth > timeTakenNinth)) {
			ninthLeg = "Complete";
			
			ninthLegTime = timeTakenNinth;	

//console.log("Time taken 9  = " + timeTakenNinth);		
//console.log("sprint time  = " + this.state.sprintTime);	
//console.log("Time racing  = " + timeRacing);		
		} else {	
			ninthLeg = "Incomplete";
		}
		
		let timeNinth = timeEighth + timeTakenNinth;
		if ((ninthLeg == "Complete") && (timeRacing - timeNinth > this.state.sprintTime)) {
			finalLeg = "Complete";
			
			finalLegTime = this.state.sprintTime;			
		} else {	
			finalLeg = "Incomplete";
		}
		
		totalTimeTaken = timeNinth + this.state.sprintTime;
		this.state.totalTimeTaken = totalTimeTaken;
		
		let whatsLeft = this.state.fuelAllowance - this.state.totalFuelUsed; // fuel left in liters at the start of the final sprint leg
		let warpBurn = whatsLeft * this.state.savedFuelApplied / 100; // amount given to warp in liters (converted from the percentage
		let finalWarpDistance = warpBurn / window.Configs.WARP_FUEL; // distance you casn travel at warp using fuel allowed for warp
		if (finalWarpDistance > finalSprintDist){
			finalWarpDistance = finalSprintDist;
		}			// distance covered by amount of fuel given to warp
		let finalWarpTime = finalWarpDistance / window.Configs.WARP_SPEED;                     // time taken to cover the warp distance
		let leftForImpulse = whatsLeft - warpBurn;                     // fuel left for final impulse 
		let finalImpulseDist = finalSprintDist - finalWarpDistance;    // distance left for final impulse and drift (or dist if only impulse)
		let finalImpulseTime = finalImpulseDist / window.Configs.IMPULSE_SPEED;  // at 2 per k
		let finalImpulseFuelBurn = finalImpulseDist * window.Configs.IMPULSE_FUEL;// at 2 per k
		let sprintTime;       // this will be the entire time to complete the final sprint
		let fuelShortfall;    // this will be the shortfall between the final impulse needs and the fuel left
		let finalDriftTime = 0;
		let finalDriftDist = 0;
//		let driftSpeed = 1;
		if (leftForImpulse >= (finalImpulseDist * 2)){ // if there is enough fuel to complete the impulse after warp spent
//			console.log(" we good ");
			sprintTime = finalWarpTime + finalImpulseTime;  // warp time as calculated above and impulse time at 1 per (this might have to be 2x)
			this.state.sprintTime = sprintTime ;
//			console.log(" sprint time  " + sprintTime);
		} else {
			fuelShortfall = (finalImpulseDist * window.Configs.IMPULSE_FUEL) - leftForImpulse;  // shortfall K calculating fuel needed (at 2L per k impulse burn rate)  minus fuel reserved, in kilometers
//			console.log("we short by " + fuelShortfall + " K");
			//  impulseCovered = leftForImpulse
			//  driftDist = shortfall in K
			finalImpulseDist = leftForImpulse / window.Configs.IMPULSE_FUEL;  //assuming impulse burn rate = 2 x k
			finalDriftDist = fuelShortfall;
			finalDriftTime = finalDriftDist * window.Configs.DRIFT_SPEED;
			sprintTime = finalWarpTime + finalImpulseTime + finalDriftTime;
			this.state.sprintTime = sprintTime ;
		}

		let iHaveFinished = false;
		
		timeTakenSoFar = timeRacing;
		if(timeTakenSoFar >= totalTimeTaken){
			timeTakenSoFar = totalTimeTaken;
			iHaveFinished = true;
		}
		


		let x = this;
		const CokeMachineFunction = () =>{
			x.setState({ Open: true });
				x.setState({ text: "Coke Machine Empty" });
		}
		
		const FuelLevelFunction = () =>{
			x.setState({ Open1: true });
				x.setState({ text: fuelAlert });
		}
		const FuelEmptyFunction = () =>{
			x.setState({ Open: true });
				x.setState({ text: "No Fuel Left, Traveling at Drift Speed." });
		}
		let cokeMachineText = <div className="cokeText">Coke</div>;
		let cokeMachine = <div className="cokeRed" onClick={() => {CokeMachineFunction();}}></div>;
		let fuelCheckText = <div className="fuelText">Fuel</div>;
		let fuelCheck = <div className="fuelGreen" onClick={() => {FuelLevelFunction();}}></div>;
		

		let firstLegComplete;
		let secondLegComplete;
		let thirdLegComplete;
		let fourthLegComplete;
		let fifthLegComplete;
		let sixthLegComplete;
		let seventhLegComplete;
		let eighthLegComplete;
		let ninthLegComplete;
		let finalLegComplete;
//		console.log("fuel check = " + this.state.fuelAllowance);
//		console.log("fuel xxx check = " + firstLegFuel);
//		console.log("lowFuelWarning = " + lowFuelWarning);
		let helmImageSwap = this.state.savedStrategy[0];
		let meteorOption = fields[0];
		if(this.state.isRacing){
			firstLegComplete = <div className="progressBarAmber">1</div>;
		}
//		console.log("helmImageSwap " + helmImageSwap);
		if (lowFuelWarning1){
			fuelCheck = <div className="fuelRed">Low Fuel</div>;
		}
		let fuelAlert = "Fuel remaining = " + this.state.fuelAllowance;
		let fuelRemaining = 0;

		if(firstLeg == "Complete"){
			fuelRemaining = fuelForSecond;
			firstLegComplete = <div className="progressBarGreen">1</div>;
			secondLegComplete = <div className="progressBarAmber">2</div>;
			 helmImageSwap = this.state.savedStrategy[1];
			 meteorOption = fields[1];
	
//		console.log("helmImage1 " + helmImageSwap);
			if (lowFuelWarning2){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
			}else{
				fuelAlert = "Fuel remaining = " + fuelForSecond;
			}
		}
	
		if(secondLeg == "Complete"){
			fuelRemaining = fuelForThird;
			secondLegComplete = <div className="progressBarGreen">2</div>;
			thirdLegComplete = <div className="progressBarAmber">3</div>;
			helmImageSwap = this.state.savedStrategy[2];
			meteorOption = fields[2];
	
//		console.log("helmImage2 " + helmImageSwap);
			if (lowFuelWarning3){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForThird;
			}
		}
		
		if(thirdLeg == "Complete"){
			fuelRemaining = fuelForFourth;
			thirdLegComplete = <div className="progressBarGreen">3</div>;
			fourthLegComplete = <div className="progressBarAmber">4</div>;
			helmImageSwap = this.state.savedStrategy[3];
			meteorOption = fields[3];
//		console.log("helmImage3 " + helmImageSwap);
			if (lowFuelWarning4){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForFourth;
			}
		}

		if(fourthLeg == "Complete"){
			fuelRemaining = fuelForFifth;
			
			fourthLegComplete = <div className="progressBarGreen">4</div>;
			fifthLegComplete = <div className="progressBarAmber">5</div>;			
			helmImageSwap = this.state.savedStrategy[4];
			meteorOption = fields[4];
//		console.log("helmImage4 " + helmImageSwap);
			if (lowFuelWarning5){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForFifth;
			}
		}
		
		if(fifthLeg == "Complete"){
			fuelRemaining = fuelForSixth;
			fifthLegComplete = <div className="progressBarGreen">5</div>;
			sixthLegComplete = <div className="progressBarAmber">6</div>;
			helmImageSwap = this.state.savedStrategy[5];
			meteorOption = fields[5];
//		console.log("helmImage5 " + helmImageSwap);
			if (lowFuelWarning6){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForSixth;
			}
		}
		
		if(sixthLeg == "Complete"){
			fuelRemaining = fuelForSeventh;
			sixthLegComplete = <div className="progressBarGreen">6</div>;
			seventhLegComplete = <div className="progressBarAmber">7</div>;
			helmImageSwap = this.state.savedStrategy[6];
			meteorOption = fields[6];
//		console.log("helmImage6 " + helmImageSwap);
			if (lowFuelWarning7){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForSeventh;
			}
		}
		
		if(seventhLeg == "Complete"){
			fuelRemaining = fuelForEighth;
			seventhLegComplete = <div className="progressBarGreen">7</div>;
			eighthLegComplete = <div className="progressBarAmber">8</div>;
			helmImageSwap = this.state.savedStrategy[7];
			meteorOption = fields[7];
//		console.log("helmImage7 " + helmImageSwap);
			if (lowFuelWarning8){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForEighth;
			}
		}
		
		if(eighthLeg == "Complete"){
			fuelRemaining = fuelForNinth;
			eighthLegComplete = <div className="progressBarGreen">8</div>;
			ninthLegComplete = <div className="progressBarAmber">9</div>;
			helmImageSwap = this.state.savedStrategy[8];
			meteorOption = fields[8];
//		console.log("helmImage8 " + helmImageSwap);
			if (lowFuelWarning9){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForNinth;
			}
		}
		
		if(ninthLeg == "Complete"){
			fuelRemaining = fuelForFinal;
			ninthLegComplete = <div className="progressBarGreen">9</div>;
			finalLegComplete = <div className="progressBarAmber">Fin</div>;
			helmImageSwap = this.state.savedStrategy[9];
			meteorOption = fields[9];
//		console.log("helmImage9 " + helmImageSwap);
			if (lowFuelWarning9){
				fuelCheck = <div className="fuelRed" onClick={() => {FuelEmptyFunction();}}>Low Fuel</div>;
				helmImageSwap = "Inertia Drift";
			}else{
				fuelAlert = "Fuel remaining = " + fuelForFinal;
			}
		}
	
		let helmImage;
		let SCREENOFF;	
		let screenButton;
	//	let screenButtonText;

		
		if (!this.state.screenOn) { //  screen is on
			helmImage = "ui horizontal segments impulseScreenOff";
			SCREENOFF = "SCREEN OFFLINE";
			screenButton = <div className="buttonGreen" onClick={() => {  this.openScreen()} }>Screen On</div>;
		
		} else { // screen is on
		
			if(this.state.isRacing){
				
				if(helmImageSwap == "Inertia Drift"){
					helmImage = "ui horizontal segments drift";
				}else if(helmImageSwap == "Impulse Engines"){
					helmImage = "ui horizontal segments impulse";
				}else if(helmImageSwap == "Warp Drive"){
					helmImage = "ui horizontal segments warp";
				}else if (helmImageSwap == "Hyper Space"){
					helmImage = "ui horizontal segments hyper";
				} else if (helmImageSwap == "Shields") {
					if (meteorOption == "Meteor Belt"){
					helmImage = "ui horizontal segments meteorShields";
					}else {
						helmImage = "ui horizontal segments impulseShields";
					}
				} else {
					helmImage = "ui horizontal segments impulse";
				}
			}
			if(!this.state.hasStarted){
				helmImage = "ui horizontal segments skyStatic";
			}
			SCREENOFF = ".";
			screenButton = <div className="buttonRed" onClick={() => {  this.closeScreen()} }>Screen Off</div>;
		}
		
//		let StartedIndicator;
//		if(this.state.hasStarted){
//			StartedIndicator = <div id='progressbar1'>progress bar 1</div>
//		} else {StartedIndicator = <div className=""></div>}
		
	//	let submitted;
		let amIRacing;
		let amIEntered;
		let submitMyFinish;
		if(!this.state.hasStarted){        // if the race has not yet started
			amIRacing = <>No Race Currently Running.</>;
			
			if(this.state.isRacing){
				amIEntered = <Segment>You are entered in the next race, please wait for the start.</Segment>
			}
	//		helmImage = "ui horizontal segments skyStatic";
		} else {                                   // if the race has started
			if(this.state.isRacing){ //            if I have entered this race
//			this.state.submitted = false;
			amIRacing = <>
							<p> Start time: { this.state.startTime }</p>
							<p> Now time: {Math.round(Date.now()/1000)}</p>
							<p> Seconds: { Math.round(Date.now()/1000) * Number(this.state.hasStarted) - this.state.startTime }</p>		
							
	//						{this.addSomeShit( 8, 3)}
							<div className="scrollableY">
								<p> Leg 1:	{firstLeg} </p>
								<p> Leg 1 time: {firstLegTime} </p>
								<p> Leg 1 Fuel: {firstLegFuel} </p>
								<p> Leg 2: {secondLeg} </p>
								<p> Leg 2 time: {secondLegTime} </p>
								<p> Leg 2 Fuel: {secondLegFuel} </p>
								<p> Leg 3: {thirdLeg} </p>
								<p> Leg 3 time: {thirdLegTime} </p>
								<p> Leg 3 Fuel: {thirdLegFuel} </p>
								<p> Leg 4: {fourthLeg} </p>
								<p> Leg 4 time: {fourthLegTime} </p>	
								<p> Leg 4 Fuel: {fourthLegFuel} </p>
								<p> Leg 5:	{fifthLeg} </p>
								<p> Leg 5 time: {fifthLegTime} </p>
								<p> Leg 5 Fuel: {fifthLegFuel} </p>
								<p> Leg 6: {sixthLeg} </p>
								<p> Leg 6 time: {sixthLegTime} </p>
								<p> Leg 6 Fuel: {sixthLegFuel} </p>
								<p> Leg 7: {seventhLeg} </p>
								<p> Leg 7 time: {seventhLegTime} </p>
								<p> Leg 7 Fuel: {seventhLegFuel} </p>
								<p> Leg 8: {eighthLeg} </p>
								<p> Leg 8 time: {eighthLegTime} </p>	
								<p> Leg 8 Fuel: {eighthLegFuel} </p>		
								<p> Leg 9: {ninthLeg} </p>
								<p> Leg 9 time: {ninthLegTime} </p>	
								<p> Leg 9 Fuel: {ninthLegFuel} </p>								
								<p> Final Sprint: {sprintTime} </p>
								<p> Final Warp: {finalWarpTime} </p>
								<p> Final Impulse: {finalImpulseTime} </p>
								<p> Final Drift: {finalDriftTime} </p>
								<p> Final Warp Burn: {warpBurn} </p>
								<p> Final Warp Distance: {finalWarpDistance} </p>
							</div>
							<p></p>
							<p> total Time: {timeTakenSoFar} </p>
							</>
						
			} else {             // race has started but I have not entered
				amIRacing = 	<>
								<h2>Not Entered In This Race</h2>
								<h3>Race Has Started, you cannot enter after start</h3>
								<h3>please enter your ARC in the next race.</h3>
								</>
							
			}
			if (iHaveFinished){             // race has started and I have finished but not submitted times yet
				amIRacing = <Segment>
				<h3>Race Number: { this.state.raceNumber }</h3>
				<p> Start time: { this.state.startTime }</p>
				<p> Your Time: {timeTakenSoFar + sprintTime} </p>
				<p>Fuel used: { totalFuelUsed }</p>
				<h2>You Have Finished.</h2>
				<p>Please submit your time and wait for race to end and results to be posted</p>
				</Segment>
				finalLegComplete = <div className="progressBarGreen">Fin</div>;
				helmImage = "ui horizontal segments skyStatic";
//				StartedIndicator = <div className="">1</div>
//				console.log("state submitted = " + this.state.submitted);
				if(this.state.submitted){      //     I have finished and sumbitted my times
					submitMyFinish = "";
				} else{
					submitMyFinish = <Button positive onClick={() => {  this.submitFinish(Math.round((timeTakenSoFar + sprintTime) * 1000))} }>Submit Finish Time</Button>;
				}
			}
			
		}

		let pickWinner;
		pickWinner = <Segment>
						something
					</Segment>
		

		if(x.state.show3){
				let { counter } = this.state;
				let timer = setTimeout(function(){
					if (counter > 0){
						x.setState({ counter: counter - 1 });
//						console.log("timer baby " + counter);
					}
				}, 1000);
		}
		let postResultsButton;
//		console.log("posted " + this.state.resultsPosted);
		if (!this.state.hasPostedWinner){
			postResultsButton = <Button positive onClick={() => {this.postResults(this.state.winnerId); this.hideModalAdminRace()}} >Post Results</Button>	
		} else{
			postResultsButton = "";
		}
		
		let mySlice;
		if (this.state.account!= undefined) {
			mySlice = this.state.account.slice(0, -34) + "..." ;
		} else {
			mySlice = "no account connected";
			this.state.text = "Please connect Metamask Wallet";
			this.setState({ Open: true });
		}
		let areGemsClaimable;
		if (this.state.gemsClaimable){
			areGemsClaimable = <div className="buttonContainer"   onClick={() => {this.claim()} } ><h4>Claim</h4></div>
		} else {
			areGemsClaimable = "";
		}

		let selectedGem = "no gem";
		let clickedGem = 0;
		let gemTrade1 ;
		let gemTrade2;
		let gemTrade3;
		let gemTradeResult;
		let select1;
		let select2;
		let select3;
		let tradeResults = JSON.stringify(this.state.gemTradeItems);
		for(let i = 0; i < this.state.gemTradeItems.length; i++){
			console.log(this.state.gemTradeItems[i]);	
		}
		
		gemTrade1 = <div className={this.state.gemTradeItems[0]} ></div>
		gemTrade2 = <div className={this.state.gemTradeItems[1]} ></div>
		let gem3 = this.state.gemTradeItems[0];
			if((((gem3 == "redTear")||(gem3 == "purpleHeart"))||((gem3 == "redHeart")||(gem3 == "blueDiamond")))||((gem3 == "purpleDiamond")||(gem3 == "redDiamond"))){
				gemTrade3 = <h3>No bonus at this level</h3>
				console.log("no trade " + this.state.gemTradeItems[0]);
			} else{		
		gemTrade3 = <div className={this.state.gemTradeItems[2]} ></div>
			}
		
		//let conversion = (some integer, say) 1 which = 2 green gem for 1 teal gem. So send the argument 1 to the contract and it auto takes 2 green gems and gives one teal gem. done.
		let tradeValue;
		if(this.state.gemTradeItems[0] == null){
			gemTrade1 = <div>Click on a gem first</div>
		}
		if(this.state.gemTradeItems[0] == "greenGem"){
			tradeValue = 2;
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="tealGem" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(0, 1)}}><h4>Select This</h4></div> </>
		}
		if(this.state.gemTradeItems[2] == "greenGem"){
			tradeValue = 4;
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueGem" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenTear" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(0, 2)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(0, 5)}}><h4>Select This</h4></div></div></>
		}
		if(this.state.gemTradeItems[0] == "tealGem"){
			tradeValue = 4;
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueGem" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenTear" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(1, 2)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(1, 5)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[2] == "tealGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleGem" ></div><h1>&nbsp;&nbsp; or </h1><div className="tealTear" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenHeart" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(1, 3)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(1, 6)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(1, 10)}}><h4>Select This</h4></div></div></>				
		}		
		if(this.state.gemTradeItems[0] == "blueGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleGem" ></div><h1>&nbsp;&nbsp; or </h1><div className="tealTear" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenHeart" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(2, 3)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(2, 6)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(2, 10)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[2] == "blueGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redGem" ></div><h1>&nbsp;&nbsp; or </h1><div className="blueTear" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealHeart" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(2, 4)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(2, 7)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(2, 11)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[0] == "purpleGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redGem" ></div><h1>&nbsp;&nbsp; or </h1><div className="blueTear" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealHeart" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(3, 4)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(3, 7)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(3, 11)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[2] == "purpleGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="blueHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(3, 8)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(3, 12)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(3, 16)}}><h4>Select This</h4></div></div></>
		}	
		if(this.state.gemTradeItems[0] == "redGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="blueHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(4, 8)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(4, 12)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(4, 16)}}><h4>Select This</h4></div></div></>
		}
		if(this.state.gemTradeItems[2] == "redGem"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="purpleHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="blueDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(4, 9)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(4, 13)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(4, 17)}}><h4>Select This</h4></div></div></>
		}	

		if(this.state.gemTradeItems[0] == "greenTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="tealTear" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenHeart" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(5, 6)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(5, 10)}}><h4>Select This</h4></div></div></>
		}
		if(this.state.gemTradeItems[2] == "greenTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="tealHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(5, 7)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(5, 11)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(5, 15)}}><h4>Select This</h4></div></div></>	
		}	
		if(this.state.gemTradeItems[0] == "tealTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="tealHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(6, 7)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(6, 11)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(6, 15)}}><h4>Select This</h4></div></div></>		
		}
		if(this.state.gemTradeItems[2] == "tealTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="blueHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(6, 8)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(6, 12)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(6, 16)}}><h4>Select This</h4></div></div></>				
		}		
		if(this.state.gemTradeItems[0] == "blueTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="blueHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(7, 8)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(7, 12)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(7, 16)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[2] == "blueTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="purpleHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="blueDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(7, 9)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(7, 13)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(7, 17)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[0] == "purpleTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redTear" ></div><h1>&nbsp;&nbsp; or </h1><div className="purpleHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="blueDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(8, 9)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(8, 13)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(8, 17)}}><h4>Select This</h4></div></div></>	
		}
		if(this.state.gemTradeItems[2] == "purpleTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redHeart" ></div><h1>&nbsp;&nbsp; or </h1><div className="purpleDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(8, 14)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(8, 18)}}><h4>Select This</h4></div></div></>	
		}	
		if(this.state.gemTradeItems[0] == "redTear"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redHeart" ></div><h1>&nbsp;&nbsp; or </h1><div className="purpleDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(9, 14)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(9, 18)}}><h4>Select This</h4></div></div></>	
		}

		if(this.state.gemTradeItems[0] == "greenHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="tealHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="greenDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(10, 11)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(10, 15)}}><h4>Select This</h4></div></div></>
		}
		if(this.state.gemTradeItems[2] == "greenHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(10, 12)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(10, 16)}}><h4>Select This</h4></div></div></>
		}	
		if(this.state.gemTradeItems[0] == "tealHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="tealDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(11, 12)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(11, 16)}}><h4>Select This</h4></div></div></>		
		}
		if(this.state.gemTradeItems[2] == "tealHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="blueDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsThree(11, 13)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(11, 17)}}><h4>Select This</h4></div></div></>			
		}		
		if(this.state.gemTradeItems[0] == "blueHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="blueDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(12, 13)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(12, 17)}}><h4>Select This</h4></div></div></>		
		}
		if(this.state.gemTradeItems[2] == "blueHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="purpleDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(12, 14)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(12, 18)}}><h4>Select This</h4></div></div></>		
		}		
		if(this.state.gemTradeItems[0] == "purpleHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redHeart" ></div><h1>&nbsp; or &nbsp;</h1><div className="purpleDiamond" ></div></div><div className="inline"><div className="buttonContainer"  onClick={() => {this.swapGemsPair(13, 14)}}><h4>Select This</h4></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(13, 18)}}><h4>Select This</h4></div></div></>		
		}
		if(this.state.gemTradeItems[0] == "redHeart"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(14, 19)}}><h4>Select This</h4></div> </>
		}
		if(this.state.gemTradeItems[0] == "greenDiamond"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="tealDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(15, 16)}}><h4>Select This</h4></div> </>
		}
		if(this.state.gemTradeItems[2] == "greenDiamond"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(15, 17)}}><h4>Select This</h4></div> </>
		}
		if(this.state.gemTradeItems[0] == "tealDiamond"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="blueDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(16, 17)}}><h4>Select This</h4></div> </>
		}
		if(this.state.gemTradeItems[2] == "tealDiamond"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsThree(16, 18)}}><h4>Select This</h4></div> </>		
		}		
		if(this.state.gemTradeItems[0] == "blueDiamond"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="purpleDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(17, 18)}}><h4>Select This</h4></div> </>
		}
		if(this.state.gemTradeItems[0] == "purpleDiamond"){
			gemTradeResult = <><div className="inlineTight"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className="redDiamond" ></div></div><div className="buttonContainer"  onClick={() => {this.swapGemsPair(18, 19)}}><h4>Select This</h4></div> </>
		}

		let approval;
		 if(this.state.currentApproval){
			 approval = <><div><h4> You have approved this contract to swap your gems</h4></div><div className='buttonContainer revokeButton'   onClick={() => {this.giveApproval(0)} } ><h4>Revoke</h4></div></>;
		} else{
			approval = <><div className="notApproved"><h3> You must give approval for this contract to swap your gems</h3></div><div className='buttonContainer approveButton'   onClick={() => {this.giveApproval(1)} } ><h4>Approve</h4></div></>;
		}



////////////////////////////////////////////////////******************************************RETURN FROM HERE ***************************************************/////////////////////////////////////////////			
return (

			<styles.StyledContainer>
				<div className="headerContainer">		
					<div className="userContainer">
						
								<Snackbar open={this.state.Open} autoHideDuration={4000} onClose={handleClose}>
									<Alert onClose={handleClose} severity="error">
										{this.state.text}
									</Alert>
								</Snackbar>
								<Snackbar open={this.state.Open1} autoHideDuration={4000} onClose={handleClose}>
									<Alert onClose={handleClose} severity="success">
										{this.state.text}
									</Alert>
								</Snackbar>
							
						<span>
							<img className="tarImageL" src={"https://tar.lets3d.com/images/TarIconL.png"}  width="120px" />
							<img className="tarImageR" src={"https://tar.lets3d.com/images/TarIcon.png"}  width="120px" />
							<h1  className="wordy">THE ALIEN RACE user console</h1>
							<h3> TAR NFT on the FUSE Network</h3>
							
								<div onClick= {() => {this.showModalNewAlf(this.state.account, this.state.balance, this.state.networkId)}}style={{cursor:'pointer'}} >
									<styles.MintButtonContainer >
										<h4>Mint a new TAR</h4>
									</styles.MintButtonContainer>
								</div>
							
						
							<h5> {this.state.contractIndicator}</h5>
							<h4>Total ARCs in circulation: { this.state.total }</h4>	
								<div onClick={(e) => {e.preventDefault(); window.open("https://wiki.tarnft.com/index.php/Main_Page")}}style={{cursor:'pointer'}} >
									<styles.MintButtonContainer >
										<h4>HELP</h4>
									</styles.MintButtonContainer>
								</div>
						</span>
						<div className="bothBoxes">
							<div className= "BiggerBox">
								<h3> <Icon name='music'/>Press play to turn the music on <Icon name='music'/></h3>
								<div>		
									<Popup inverted content="Play Audio" trigger = <Icon name='play' size='large' onClick={() => this.trackChangePlay() }></Icon>/>		
									<Popup inverted content="Pause Audio" trigger = <Icon name='pause' size='large' onClick={() => this.trackPause()}></Icon>/>
									<Popup inverted content="Stop Audio" trigger = <Icon name='stop' size='large' onClick={() => this.trackChangeStop()}></Icon>/>
								</div>								
								<div className="trackButtons">
									<styles.ButtonContainer className="trackDown" onClick= {() => {this.changeTrackUp()}} >
										<h3>Track up</h3>
									</styles.ButtonContainer>
									<styles.ButtonContainer className="trackUp" onClick= {() => {this.changeTrackDown()}} >
										<h3>Track down</h3>
									</styles.ButtonContainer>	
								</div>
							</div>	
							<div className={this.state.audioClass}>							
								<h4>Soundtrack</h4> 
								<h3>{this.state.trackText} </h3>
								<h4>www.scottbuckley.com</h4>
							</div>
						</div>
								
						
						<div className="purpleContainer">
							<h3>Current Race Number { this.state.raceNumber }</h3>
							<h3>{currentRace}</h3>
							<div className="inline">	
								<div className="buttonContainer" onClick= {() => {this.showModalLeaderboard(this.state.account, this.state.networkId); this.getLeaderboardData()}} >
								
										<h4>Leaderboard</h4>
									
								</div>
								
								<div></div>
								<div  className="buttonContainer" onClick= {() => {
						//			console.log("account = " + this.state.account);
									if(this.state.account == "0x7Fb34562fC04e875aA9d9a4Fb04E6db99C2b4c6C"){ // spark
								//	if(this.state.account == "0x7B420EBd692CC83443a215c6BEaF642CbEab2f97"){ // ganache
										this.showModalAdminRace(this.state.networkId);
										} else {
											this.showModalUserRace(this.state.networkId);
										}
									}} >
									
										<h4>Race Overview</h4>
									
								</div>
							</div>	
							<div>
								<h3>Distance: { this.state.distance }</h3>
							</div>
							<div>
								<h3>{startingTime}</h3>
							</div>						
						</div>
						
						<div className="purpleContainer">
												
							<div className="inline">	
								<div>	
									<h2> {this.state.registeredUserHandle}</h2>
									<div  className="buttonContainer" onClick= {() => {this.showModalRegisterName(this.state.account, this.state.networkId)} }>	
										<h4>{ claimUserName }</h4>
									</div>								
								</div>
								<div>
									<h3>My Account</h3>
									<h2>Prestige: {this.state.prestige}</h2>
								</div>					
								<div>
									<h2> Gems : {this.state.gemOwnerCount}</h2>				
									<div  className="buttonContainer" onClick= {() => {this.showModalGems(this.state.account, this.state.balance, this.state.networkId); this.loadGems()}} >
										<h4> Show Gems </h4>
									</div>
								</div>
							</div>	
						</div>			
						
					</div>	
				</div>	
				
				<div className="headerContainer">
					<div className="userContainer">				
						<div className="alfBorder">	
							<div className="purpleContainer" >
								<div className="inline">			
									<h2> MY Alien Race Creatures: { this.state.balance } </h2>
									<div  className="buttonContainer" onClick= {() => {this.showModalNewAlf(this.state.account, this.state.balance, this.state.networkId)}} >
										<h4>Mint a new TAR</h4>
									</div>	
								</div>
								<div className="scrollableX  console-image">			
									<div className="ui horizontal segments console-image">
										{this.state.myAliensArray.map((alf) =>(
											<div className=" " key={alf.id}>	
												<div className="ui horizontal segments animBack" style={{width: '190px', height: 'auto'}} >
													<Form.Field  className="ui segment " onClick={(e) => {this.showModal(alf.alf_name, alf.id, alf.finalFuel, alf.races, alf.finishes, alf.wins, alf.myCooldown, alf.myRaceNumber, alf.strategy, alf.recentTime, alf.isRacing, alf.hasSubmitted, e)}}>
														<div>
															<styles.Card >
																<div  >
																	<h3>Id: { Number.parseInt(alf.id) + 1 }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; { alf.alf_name }</h3>															
																	<img src={"https://tarnft.lets3d.com/hidden.jpg"} width="100px" alt="My Alien" className="ui small image"/>
																	<h4>Level: { Number(alf.races) + Number(alf.finishes) }</h4>
																	<h4>Starts: { alf.races }</h4>
																	<h4>Finishes: { alf.finishes }</h4>
																	<h4>Wins: { alf.wins }</h4>
																	<h4>cooldown: { this.calculateTime(alf.myCooldown) } </h4>
																
																	<h3>Is Racing: { String(alf.isRacing) }</h3>
																		{this.needsPlot( alf.isRacing, alf.strategy)}
																</div>
															</styles.Card>
														</div>
													</Form.Field>	
												</div>
											</div>
										))}
									</div>
								</div>	
		
							</div>
						</div>
					</div>	
				</div>	
	
<Modal show = {this.state.show}  handleClose = {this.hideModal} id = {Number.parseInt(this.state.id) + 1} name = {this.state.name} raceNumber = {this.state.raceNumber}>
				
						
							<div className="MyAlfHeader">
								<div className="MyAlfContainer">	
									<div className="alienImage">
										<img  src={"https://tarnft.lets3d.com/hidden.jpg"} alt="My Alien"/>
									</div>
									<div className="inline">
										<h2>Name: { this.state.name }</h2>
									
										<h2>Id: { Number.parseInt(this.state.id) + 1 }</h2>
									</div>
										<Form onSubmit={(e) => {this.handleAlfNameChange(e)}}>
											<Form.Field>
												<input  placeholder= "Enter New Name" onChange={(e) => {this.handleAlfNameInput(e)}} />
											</Form.Field>
											<Button color="orange" type='submit'>Change ARC Name</Button>
										</Form>
										
								</div>		
								<div className="inline">
									<div className="StatsContainer">	
										<h2>Stats </h2>
										<p>Level: { Number(this.state.races) + Number(this.state.finishes) }</p>
										<p>Races: { this.state.races }</p>
										<p>Finishes: { this.state.finishes }</p>
										<p>Wins: { this.state.wins }</p>
										<p>Time: { this.state.recentTime / 1000} </p>
										<p>My Cooldown: { this.calculateTime(this.state.myCooldown) } hrs</p>
										
										<h3>{ this.showChamps(this.state.id) }</h3>
									</div>
									

									<div className="StatsContainer">
										<Segment.Group >
											<h4>{entered}</h4>
											{enterRaceButton}
											{TheHelm}


										</Segment.Group>	
										<h3> Race: { currentRaceNumber }</h3>
										<h3> Has Started: { String(this.state.hasStarted) }</h3>
										<p> Distance: { this.state.distance }</p>
										<p> Start Time: { this.state.formattedStartTime }</p>	
										<h3> Race Type: { this.state.raceType }</h3>
										<h3> Cooldown: { this.state.cooldown }hrs.</h3>
									<p></p>
									<p> Entry Fee: { this.state.entryFee/1000000000000000000 }</p>
									<p> Prize:  { this.state.prize/1000000000000000000 } fuse</p>
										
										
									</div>
								</div>
							</div>					
								
				</Modal>
				
<ModalAdminRace show2 = {this.state.show2}  handleClose2 = {this.hideModalAdminRace} race = { this.state.raceNumber }>
					<div className="inline ">
						
							<div className="adminFieldContainer greyContainer">
								<h2>Select conditions for race: { this.state.raceNumber }</h2>
								<div className="raceField">
									<div className="inline">
										<div  className="flexColumn">
											<h3>New Field</h3>
											<h3></h3>
											<p>1: { fields[0] } </p>
											<p>2: { fields[1] } </p>
											<p>3: { fields[2] } </p>
											<p>4: { fields[3] } </p>
											<p>5: { fields[4] } </p>
											<p>6: { fields[5] } </p>
											<p>7: { fields[6] } </p>
											<p>8: { fields[7] } </p>
											<p>9: { fields[8] } </p>
										</div>
										<div className="flexColumn" >
											<h3>Set </h3>
											<h3></h3>
											<p>Leg 1: { this.state.stringMyConditions[0] } </p>
											<p>Leg 2: { this.state.stringMyConditions[1] } </p>
											<p>Leg 3: { this.state.stringMyConditions[2] } </p>
											<p>Leg 4: { this.state.stringMyConditions[3] } </p>
											<p>Leg 5: { this.state.stringMyConditions[4] } </p>
											<p>Leg 6: { this.state.stringMyConditions[5] } </p>
											<p>Leg 7: { this.state.stringMyConditions[6] } </p>
											<p>Leg 8: { this.state.stringMyConditions[7] } </p>
											<p>Leg 9: { this.state.stringMyConditions[8] } </p>								
										</div>
										<div className="flexColumn" >
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
										</div>
										<div className="flexColumn" >
											<h3> 1: Leg </h3>
											{ field1 }
											{ field2 }
											{ field3 }
											{ field4 }
											{ field5 }
											{ field6 }
											{ field7 }
											{ field8 }	
											{ field9 }											
										</div>
										<div className="flexColumn" >
											<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
										</div>
										<div className="flexColumn" >
										
											<h3> 2: Condition </h3>
											<h4> Setting leg: { this.state.thisField }</h4>
											{ AllClear }
											{ Meteors }
											{ Pirates }
											{ PirateRadar }
											{ GravityStar }
											{ WormHole }
											{ PlasmaField }
											<Form.Field>
												<input size='12' placeholder= "Enter Fuel" onChange={(e) => {this.handleFuelInput(e)}} />
											</Form.Field>
											<Form.Field>
												<input size='12' placeholder= "Distance" onChange={(e) => {this.handleDistanceInput(e)}} />
											</Form.Field>
											<p> {lockFieldButton}</p>
										</div>	
									</div>
									
								</div>
								<div className="flexColumn" >
									<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
								</div>
								<div className="inline">
									<div className="raceField">
										<h3>Set Non-standard options</h3>
										{ Standard }
										{ Beginners }
										{ Maiden }
										{ Featured }
										{ Championship }

										<Form.Field>
											<input size='12' placeholder= "Enter Fee" onChange={(e) => {this.handleEntryFeeInput(e)}} />
										</Form.Field>
										<Form.Field>
											<input size='12' placeholder= "Enter Prize" onChange={(e) => {this.handlePrizeInput(e)}} />
										</Form.Field>
										
										<Form.Field>
											<input size='12' placeholder= "Enter Cooldown" onChange={(e) => {this.handleCooldownInput(e)}} />
										</Form.Field>
										
										<Form.Field>
											<input size='12' placeholder= "Enter Prestige" onChange={(e) => {this.handlePrestigeInput(e)}} />
										</Form.Field>
									</div>
									
									<div className="raceField">
									<h3> Presets</h3>
									<p></p>
										<p> Race Type: { this.state.setRaceType }</p>
										<p> Entry Fee: { this.state.setEntryFee } Fuse</p>
										<p> Prize: { this.state.setPrize } Fuse</p>
										<p> Cooldown: { this.state.setCooldown } Hrs. </p>
										<p> Win prestige: { this.state.setPrestige } Points</p>
										<p>{ resetValues }</p>
									</div>
								</div>
							</div>
						
						
							<div className="adminRaceContainer greyContainer">
								
									<h3>Race Has Started: { String(this.state.hasStarted) }</h3>
								
									{startRaceButton}
								
									<p> Start Time: { this.state.formattedStartTime }</p>
									<p>Race Type: { this.state.raceType }</p>
									<p>Entry Fee: { this.state.entryFee } Fuse</p>
									<p>Cooldown: { this.state.cooldown } hrs</p>
									<p>Distance: { this.state.distance } klm</p>
									<p>Fuel: { this.state.fuelAllowance }</p>
									<p> Prestige available: </p>
									<p>Join:1, Complete:1, Win: {this.state.winPrestige}</p>
								
								<h3>Entrants: { this.state.racers.length }</h3>
								<div className="scrollBox">
									<div className="scrollable">
										{this.state.racers.map((entrant, racersIndex) =>(
											<div className=" " key={racersIndex}>	
												<styles.RaceEntrants>
													<p>Entrant: {Number.parseInt(racersIndex) + 1} - Name: {this.state.racersName[racersIndex]} - Id: {Number.parseInt(entrant) + 1}</p>	
												</styles.RaceEntrants>
											</div>
										))}
									</div>
								</div>
								<div  className="buttonContainer" onClick= {() => {this.showModalAirdrop(this.state.racerReward)}} >
								<h3>Airdrop</h3>
								</div>
							</div>
						
						
							<div className="adminRaceContainer greyContainer">
								<h2>Last Race Results Race: { this.state.raceNumber }</h2>
								<h3>Winner is:</h3>
								<h3>{this.state.winnerName} Id: {Number.parseInt(this.state.winnerId) + 1}</h3>
								<div className="scrollBox">
									<div className="scrollable">
										{this.state.finishers.map((finisher, finishersIndex) =>(
											<div className=" " key={finishersIndex}>	
												<styles.RaceFinishers>
													<p>Finish Time: {this.state.finishersTime[finishersIndex]} - Name: {this.state.finishersName[finishersIndex]} - Id: {Number.parseInt(finisher) + 1}</p>	
												</styles.RaceFinishers>
											</div>
										))}
									</div>
								</div>	
								<p>	</p>	
								

									{postResultsButton}
							
							
								<p>	</p>	
								Results will be cleared at the start of the next race.
						
							</div>
						
					</div>
				</ModalAdminRace>	
				
<ModalHelm show3 = {this.state.show3}  handleClose3 = {this.hideModalHelm} id = {Number.parseInt(this.state.id) + 1} name = {this.state.name}>

					<p>Race Progress => Total Distance: { this.state.distance } klm -|- Fuel Allowance: { this.state.fuelAllowance }</p>
					<p> SECONDS LEFT: {this.state.counter} Fuel Remaining: {fuelRemaining}</p>
					
					<div className="scrollableHelm">
					
								<div className="helmBorder">	
									{amIRacing}
									{amIEntered}
									{submitMyFinish}
								</div>	
						
								<div className="helmContainer">

											<div  className={helmImage} >
												<Segment>
													<p></p>
													<h3 style={{color:"#cb6"}}>{SCREENOFF}</h3>
													<p>.</p>
													<p>.</p>
													<p>.</p>
													<p>.</p>
													<p>.</p>
													<p>.</p>	
													<p>.</p>
													<p>.</p>	
												</Segment>
											</div>
										
										
										<div className = "dashBoard">
											<h4>{cokeMachineText}</h4>
											<h4>{cokeMachine}</h4>
											<h4>{fuelCheck}</h4>
											<h4>{fuelCheckText}</h4>
											
										</div>

										<h4>{screenButton}</h4>

										<div className="progressBarBorder">
											<div className="progressBar">
												{firstLegComplete}{secondLegComplete}{thirdLegComplete}{fourthLegComplete}{fifthLegComplete}{sixthLegComplete}{seventhLegComplete}{eighthLegComplete}{ninthLegComplete}{finalLegComplete}
											</div>
										</div>
										
									<h3>Current engine = {helmImageSwap}</h3>
									<h3>Current field = {meteorOption}</h3>
								</div>

								<div className="helmBorder">	
									
									<h4>Current Strategy</h4>
									<p></p>
									<p>Leg 1: { this.state.savedStrategy[0] } </p>
									<p>Leg 2: { this.state.savedStrategy[1] } </p>
									<p>Leg 3: { this.state.savedStrategy[2] } </p>
									<p>Leg 4: { this.state.savedStrategy[3] } </p>
									<p>Leg 5: { this.state.savedStrategy[4] } </p>
									<p>Leg 6: { this.state.savedStrategy[5] } </p>
									<p>Leg 7: { this.state.savedStrategy[6] } </p>
									<p>Leg 8: { this.state.savedStrategy[7] } </p>
									<p>Leg 9: { this.state.savedStrategy[8] } </p>
									<p>Final fuel burn: { "this.state.fuel" } %</p>
								</div>	
								<div className="helmBorder">	
									
									<h4>FieldConditions</h4>
									<p></p>
									<p>Leg 1: { fields[0] } </p>
									<p>Leg 2: { fields[1] } </p>
									<p>Leg 3: { fields[2] } </p>
									<p>Leg 4: { fields[3] } </p>
									<p>Leg 5: { fields[4] } </p>
									<p>Leg 6: { fields[5] } </p>
									<p>Leg 7: { fields[6] } </p>
									<p>Leg 8: { fields[7] } </p>
									<p>Leg 9: { fields[8] } </p>
									
								</div>	
						</div>
				
				</ModalHelm>					

<ModalPlot show4 = {this.state.show4}  handleClose4 = {this.hideModalPlot} race = {this.state.raceNumber}>				
					<div className="inline">
						<h2>{ this.state.name }</h2>
						<h2> id: { Number.parseInt(this.state.id) + 1 } </h2>
					</div>
					<div className="scrollableHelm">
						<div className="plotInline">		
							<div className ="plotSquatContainer greyContainer">
								<h3> Total Distance: { this.state.distance } klm</h3>
								<h3>total fuel : {this.state.fuelAllowance}</h3>
								<styles.ButtonContainer className="trackDown" onClick= {() => {this.setState({ Open1: true }); this.setState({ text:  "Leg1: " + fields[0] +" -.- Leg2: "+ fields[1] + " -.- Leg3: " + fields[2] + " -.- Leg4: "+ fields[3] + " -.- Leg5: " + fields[4] + " -.- Leg6: "+ fields[5] + " -.- Leg7: " + fields[6] + " -.- Leg8: "+ fields[7] + " -.- Leg9: "+ fields[8] })}} >
									<h3>Conditions</h3>
								</styles.ButtonContainer>
							</div>
								
							<h2>Select Engine for leg: { this.state.thisLeg }</h2>
							<div className="inline">	
							<h3> </h3><h3> </h3><h3> </h3>
								<h3 style={{color:"#C9A709"}}> 1: Choose Leg (click one here first)</h3>
								<h3> </h3>
								<h3 style={{color:"#C9A709"}}> 2: Choose Engine (then click one here)</h3>
							</div>
							<div className="inline">
							
								<div className="plotField greyContainer">	
									
									<h4>FieldConditions</h4>
									<p></p>
									<p>Leg 1: { fields[0] } </p>
									<p>Leg 2: { fields[1] } </p>
									<p>Leg 3: { fields[2] } </p>
									<p>Leg 4: { fields[3] } </p>
									<p>Leg 5: { fields[4] } </p>
									<p>Leg 6: { fields[5] } </p>
									<p>Leg 7: { fields[6] } </p>
									<p>Leg 8: { fields[7] } </p>
									<p>Leg 9: { fields[8] } </p>
									
								</div>
								<div className="chooseLegContainer greyContainer">

									<div>
										<div className="inline">	{ leg1 }<div className="legStrategy">{ this.state.stringMyStrategy[0] }</div></div>
										<div className="inline">	{ leg2 }<div className="legStrategy">{ this.state.stringMyStrategy[1] }</div></div>
										<div className="inline">	{ leg3 }<div className="legStrategy">{ this.state.stringMyStrategy[2] }</div></div>
										<div className="inline">	{ leg4 }<div className="legStrategy">{ this.state.stringMyStrategy[3] }</div></div>
										<div className="inline">	{ leg5 }<div className="legStrategy">{ this.state.stringMyStrategy[4] }</div></div>
										<div className="inline">	{ leg6 }<div className="legStrategy">{ this.state.stringMyStrategy[5] }</div></div>
										<div className="inline">	{ leg7 }<div className="legStrategy">{ this.state.stringMyStrategy[6] }</div></div>
										<div className="inline">	{ leg8 }<div className="legStrategy">{ this.state.stringMyStrategy[7] }</div></div>
										<div className="inline">	{ leg9 }<div className="legStrategy">{ this.state.stringMyStrategy[8] }</div></div>
										<div className="inline">{FinalSprint}<div className="legStrategy">{this.state.savedFuelApplied}</div></div>
									</div>	
								</div>
								<div className="chooseEngineContainer greyContainer">
									
									<div>
										{ inertiaButton }
										{ impulseButton }
										{ warpButton }
										{ hyperButton }
										{ shieldsButton }
									</div>
									<p></p>
									<div>{this.state.finalFuel}</div>
										<p></p>
										<div> {lockButton}</div>
								</div>	
							</div>
							<div className="plotFieldMobile greyPlotContainer">	
								<h4>FieldConditions</h4>
								<p></p>
								<p>Leg 1: { fields[0] } </p>
								<p>Leg 2: { fields[1] } </p>
								<p>Leg 3: { fields[2] } </p>
								<p>Leg 4: { fields[3] } </p>
								<p>Leg 5: { fields[4] } </p>
								<p>Leg 6: { fields[5] } </p>
								<p>Leg 7: { fields[6] } </p>
								<p>Leg 8: { fields[7] } </p>
								<p>Leg 9: { fields[8] } </p>	
							</div>		
						</div>	
					</div>
			</ModalPlot>
				
<ModalRegisterName show5 = {this.state.show5}  handleClose5 = {this.hideModalRegisterName} user = {this.state.account} name = {this.state.registeredUserHandle}>
					
							<h3>Your wallet address </h3>
							<h4 className="blueText"> {this.state.account}</h4>
							<h2 className="redText">Cost to change username: { window.Configs.NAME_COST_DISPLAY } Fuse</h2>
							<h3>Current registered name </h3>
							
							<h2 className="blueText"> {this.state.registeredUserHandle}</h2>
							<Form onSubmit={(e) => {this.handleForm(e)}}>
								<Form.Field>
									<label>Your Desired Name</label>
									<h2> {approvalText}</h2>
									<input placeholder= "Enter your User Name" onChange={(e) => {this.handleMyNameCheck(e)}} />
								</Form.Field>
								<Button color="orange" type='submit'>Check Availability</Button>
								{claimNameButton} 
							</Form>
						
				</ModalRegisterName>	

<ModalMintNewAlf show6 = {this.state.show6}  handleClose6 = {this.hideModalNewAlf} user = {this.state.account} balance = {this.state.balance}>
					<div className="MintContainer">
							<h3>Your wallet address = </h3>
							<h4> {this.state.account }</h4>
							<h3> Your Arc Balance = {this.state.balance }</h3>
							<Form onSubmit={(e) => {this.handleMint(e)}}>
								<Form.Field>
									<label>Name your new Alien. Every new alien minted gains you 5 Prestige points.</label>
									<input placeholder= "Enter your Aliens Name" onChange={(e) => {this.handleMyAlfMint(e)}} />
									<label>If you mint more than one alien, all the aliens will have the same name. 
										They will all have different IDs so you can tell the apart. You can rename each one after minting if you choose.</label>
									<input placeholder= "Enter quantity to mint" onChange={(e) => {this.handleMyAlfQty(e)}} />
								</Form.Field>
								<Button color="green" type='submit'>Mint</Button>
							</Form>
						</div>
				</ModalMintNewAlf>		

<ModalLeaderboard show7 = {this.state.show7}  handleClose7 = {this.hideModalLeaderboard} user = {this.state.account} race = {this.state.raceNumber} circ = { this.state.total }>
					

				
				
						<div className="TrophyContainer">

							<div className="leaders">
								<h3> Leaders by prestige:</h3>
								{this.state.myAchieversArrayPres.map((achiever, presIndex) =>(
									<div className=" " key={presIndex}>	
																				
										<div className="leaderboardEntries">
											<p><b>{Number.parseInt(presIndex) + 1} - {this.state.myAchieversArrayPres[presIndex][5]} - PRES: {Number.parseInt(this.state.myAchieversArrayPres[presIndex][3])+Number.parseInt(this.state.myAchieversArrayPres[presIndex][4])}</b></p>	
										</div>
									</div>
								))}								
							</div>	
							<div className="leaders">	
								<h3> NFT owners by count:  </h3>
								{this.state.myAchieversArrayNFT.map((achiever, achieverIndex) =>(
									<div className=" " key={achieverIndex}>	
										<div className="leaderboardEntries">
											<p><b>{Number.parseInt(achieverIndex) + 1} - {this.state.myAchieversArrayNFT[achieverIndex][5]} - NFTs: {this.state.myAchieversArrayNFT[achieverIndex][1]}</b></p>	
										</div>
									</div>
								))}	
							</div>	
							<div className="leaders">	
								<h3> Gem owners by value: </h3>
								{this.state.gemAchieversArray.map((gemOwner, gemIndex) =>(
									<div className=" " key={gemIndex}>	
										<div className="leaderboardEntries">
											<p><b>{Number.parseInt(gemIndex) + 1} - {this.state.gemAchieversArray[gemIndex][5]} - gems: {this.state.gemAchieversArray[gemIndex][4]}</b></p>	
										</div>
									</div>
								))}								
							</div>	
							<div className="leaders">	
								<h3> Most player wins: </h3>
								{this.state.winAchieversArray.map((winner, winnerIndex) =>(
									<div className=" " key={winnerIndex}>	
										<div className="leaderboardEntries">
											<p><b>{Number.parseInt(winnerIndex) + 1} - {this.state.winAchieversArray[winnerIndex][5]} - wins {this.state.winAchieversArray[winnerIndex][2]}</b></p>	
										</div>
									</div>
								))}									
							</div>	
							<div className="leaders">	
								<h3> most alien wins: </h3>
								
								{this.state.winAliensArray.map((alien, winnerIndex) =>(
									<div className=" " key={winnerIndex}>	
									<Form.Field  onClick={(e) => {this.showModalWinner(alien.alf_name, alien.id, alien.races, alien.finishes, alien.wins, alien.myCooldown, alien.recentTime, e);this.hideModalLeaderboard()}}style={{cursor:'pointer'}} >
										<div className="leaderboardEntries">
											<p><b>{Number.parseInt(winnerIndex) + 1} - {this.state.winAliensArray[winnerIndex].alf_name} - {this.state.winAliensArray[winnerIndex].wins }</b></p>	
										</div>
										</Form.Field>
									</div>
								))}	
								
							</div>								
						</div>

						<h1 color="yellow">Winners: </h1>
						<div className="leaderBoardContainer">
							
													
									<div className="scrollableXLeaderboard">
									
										<div className=" console-image leaderboardCard"   >
											{this.state.myWinnersArray.map((winner) =>(
												<div className=" " key={winner.id}>	
<Form.Field  onClick={(e) => {this.showModalWinner(winner.name, winner.winnerId, this.state.winAliensArray[parseInt(winner.winnerId)].races, this.state.winAliensArray[winner.winnerId].finishes, this.state.winAliensArray[winner.winnerId].wins, this.state.winAliensArray[winner.winnerId].myCooldown, this.state.winAliensArray[winner.winnerId].recentTime, e);this.hideModalLeaderboard();console.log("Hello " + winner.winnerId) }}style={{cursor:'pointer'}} >												
													<div className="winnerContainer">
														<div className="paddingOverride">
															<h4>Race: { Number.parseInt(winner.race)}</h4>
															<img  src={"https://fusible.link/aliens/trophy.jpg"}  width="20%" />
															<h4>1st Place</h4>
														</div>
														{this.checkChamp(winner.isChampionship)}
														<h3> { winner.name }</h3>	
														<img  src={"https://tarnft.lets3d.com/hidden.jpg"} width="100px" alt="My Alien" className="ui small  image"/>
														<h4>Time: { winner.time / 1000}</h4>	
														<h4>Owner: { winner.tokenOwner.slice(0, -34) + "..." }</h4>
														<h4>OwnerName: {this.state.namesCount[winner.tokenOwner]}</h4>
														<h4> Id: {Number.parseInt(winner.winnerId) + 1 }</h4>
													</div>
													</Form.Field>
												</div>
											))}
										</div>	
										
									
									</div>
								
								
								
						</div>
				
				</ModalLeaderboard>		
				
				
<ModalUserRace show8 = {this.state.show8}  handleClose8 = {this.hideModalUserRace} race = { this.state.raceNumber } ready = {this.state.raceReady}>
				<div className="inlineDesktop">
					<div className="userRace1">
							<div className="userRace1a greyContainer">

								<h4>1: { fields[0] } </h4>
								<h4>2: { fields[1] } </h4>
								<h4>3: { fields[2] } </h4>
								<h4>4: { fields[3] } </h4>
								<h4>5: { fields[4] } </h4>
								<h4>6: { fields[5] } </h4>
								<h4>7: { fields[6] } </h4>
								<h4>8: { fields[7] } </h4>
								<h4>9: { fields[8] } </h4>
								
							</div>

					
							<div className="userRace1b greyContainer">
								
								
								<h4>Difficulty: Easy</h4>
								<h4> Cooldown: { this.state.cooldown } hrs</h4>
								<h4>Total Distance: { this.state.distance }</h4>
								<h4>Fuel: { this.state.fuelAllowance }</h4>
								<div className="racePrestige greyContainer">
								
									<h3> Prestige Available:</h3>
									
									<h4> Join: 1</h4>
									<h4> Complete: 1</h4>
									<h4> Win: 2</h4>
								</div>
								<div className="racePrestige greyContainer">
									<h4> Race Type: { this.state.raceType }</h4>
									<h4></h4>
									<h4> Entry Fee: { this.state.entryFee/1000000000000000000 } Fuse</h4>
									<h4> Prize: { this.state.prize/1000000000000000000 } Fuse</h4>
								</div>
							
							</div>
												
					</div>
					<div className="inline">
						<div className="userRace3 greyContainer">
							<h3>Race Has Started: { String(this.state.hasStarted) }</h3>
							<h3> Start Time: { this.state.formattedStartTime }</h3>
							<h3>Entrants: { this.state.racers.length }</h3>
							<div className="scrollBox">
								<div className="scrollable">
									{this.state.racers.map((entrant, racersIndex) =>(
										<div className=" " key={racersIndex}>	
											<div className="raceEntrants greyContainer raceInline">
												<h4>Name: {this.state.racersName[racersIndex]} </h4>
												<h4>Id: {Number.parseInt(entrant) + 1}</h4>	
											</div>
										</div>
									))}
								</div>
							</div>

						</div>
					
					
						<div className=" userRace4 greyContainer">
							<h3>Last Race Results- Race: { this.state.raceNumber }</h3>
							<h3>The Winner is </h3>
							<h3>{this.state.winnerName} Id: {Number.parseInt(this.state.winnerId) + 1}</h3>
							<div className="scrollBox">
								<div className="scrollable">
									{this.state.finishers.map((finisher, finishersIndex) =>(
										<div className=" " key={finishersIndex}>	
											<div className="raceFinishers greyContainer raceInline">
												<h4>{this.state.finishersName[finishersIndex]} - Id: {Number.parseInt(finisher) + 1}</h4>
												<h4>Time: {this.state.finishersTime[finishersIndex] * 0.001} </h4>
													
											</div>
										</div>
									))}
								</div>
							</div>	
							
							
						</div>
						
					</div>
				</div>	
			</ModalUserRace>
			
			<ModalWhatsThis show9 = {this.state.show9}  handleClose9 = {this.hideModalWhatsThis}>
				<div>
					<h3>It may take some time to learn the most effective values for this leg.</h3>
					<h3><i>It is the most important strategy so keep track of your results.</i></h3>
				</div>
			</ModalWhatsThis>	
			
			<ModalFrontPage  show10 = {this.state.show10}  handleClose10 = {this.hideModalFrontPage } network = {this.state.networkId}>
			
			<h1>hello</h1>
			</ModalFrontPage>	
	<ModalAirdrop  show11 = {this.state.show11}  handleClose11 = {this.hideModalAirdrop }>
				<div>
					
					<h3>Use mapping across array to pay gems</h3>
					<p>one starter = ({this.state.racerReward[0]}) </p>
				<p>two starter = ({this.state.racerReward[1]}) </p>
					<p> length = ({this.state.racerReward.length}) </p>
				</div>	
				<div>
				<h2>finishers</h2>
				<p> one finisher = ({ this.state.finisherOwners[0] }) </p>
				<p> two finisher = ({ this.state.finisherOwners[1] }) </p>
				<p> one finisher length = ({ this.state.finisherOwners.length }) </p>
				</div>
				<Button positive onClick={() => {console.log("rewards given");this.gemToAllStarters();}} >loadAirdrop all starters</Button>
				<Button positive onClick={() => {console.log("rewards given " + JSON.stringify(this.state.finisherOwners));this.loadAirdrop(this.state.finisherOwners, 1);}} >loadAirdrop all finishers</Button>
			</ModalAirdrop>	
			
	<ModalGems  show12 = {this.state.show12}  handleClose12 = {this.hideModalGems } gemBalance = {this.state.gemOwnerCount}>
	<div className="bigGemDiv">
		<div className="inlineDesktop">
			<div className="allCards">

				<div className="inline">
				
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenGem"}); this.addMyGem("greenGem", 0)}}>
						<h3> Gem </h3>
						<h4> 1 point </h4>
						
						<div className="greenGem" >
						</div>
						<h4>{this.state.gemBalance[0]}</h4>
					</div>	
					<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealGem"}); this.addMyGem("tealGem", 1)}}>	
						<h3> Gem </h3>
						<h4>  2 point </h4>
						
						<div className="tealGem" >
						</div>
						<h4>{this.state.gemBalance[1]}</h4>
					</div>			
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueGem"}); this.addMyGem("blueGem", 2)}}>
						<h3> Gem  </h3>
						<h4>  4 point </h4>
						
						<div className="blueGem" >	
						</div>	
						<h4>{this.state.gemBalance[2]}</h4>
					</div>
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleGem"}); this.addMyGem("purpleGem", 3)}}>	
						<h3> Gem  </h3>
						<h4>  8 point</h4>
						
						<div className="purpleGem" >
						</div>
						<h4>{this.state.gemBalance[3]}</h4>
					</div>					
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redGem"}); this.addMyGem("redGem", 4)}}>	
						<h3> Gem  </h3>
						<h4>  16 point</h4>
						
						<div className="redGem" >	
						</div>
						<h4>{this.state.gemBalance[4]}</h4>
					</div>				
				</div>
				<div className="inline">
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenTear"}); this.addMyGem("greenTear", 5)}}>
						<h4> Teardrop </h4>
						<h4> 4 points</h4>
						
						<div className="greenTear" >
						</div>
						<h4>{this.state.gemBalance[5]}</h4>
					</div>	
						<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealTear"}); this.addMyGem("tealTear", 6)}}>	
						<h3> Teardrop  </h3>
						<h4>  8 point </h4>
						
						<div className="tealTear" >
						</div>
						<h4>{this.state.gemBalance[6]}</h4>
					</div>		
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueTear"}); this.addMyGem("blueTear", 7)}}>
						<h3> Teardrop  </h3>
						<h4>  16 point </h4>
						
						<div className="blueTear" >	
						</div>	
						<h4>{this.state.gemBalance[7]}</h4>
					</div>	
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleTear"}); this.addMyGem("purpleTear", 8)}}>	
						<h3> Teardrop  </h3>
						<h4>32 point </h4>
						
						<div className="purpleTear" >
						</div>
						<h4>{this.state.gemBalance[8]}</h4>
					</div>				
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redTear"}); this.addMyGem("redTear", 9)}}>	
						<h3> Teardrop  </h3>
						<h4>  64 point </h4>
						
						<div className="redTear" >	
						</div>
						<h4>{this.state.gemBalance[9]}</h4>
					</div>				
				</div>	
				<div className="inline">
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenHeart"}); this.addMyGem("greenHeart", 10)}}>
						<h3> Heart </h3>
						<h4> 8 point </h4>
						
						<div className="greenHeart" >
						</div>
						<h4>{this.state.gemBalance[10]}</h4>
					</div>	
						<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealHeart"}); this.addMyGem("tealHeart", 11)}}>	
						<h3> Heart  </h3>
						<h4>  16 point </h4>
						
						<div className="tealHeart" >
						</div>
						<h4>{this.state.gemBalance[11]}</h4>
					</div>			
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueHeart"}); this.addMyGem("blueHeart", 12)}}>
						<h3> Heart  </h3>
						<h4>  32 point</h4>
						
						<div className="blueHeart" >	
						</div>	
						<h4>{this.state.gemBalance[12]}</h4>
					</div>	
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleHeart"}); this.addMyGem("purpleHeart", 13)}}>	
						<h3> Heart  </h3>
						<h4>  64 point</h4>
						
						<div className="purpleHeart" >
						</div>
						<h4>{this.state.gemBalance[13]}</h4>
					</div>					
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redHeart"}); this.addMyGem("redHeart", 14)}}>	
						<h3> Heart  </h3>
						<h4> 128 point </h4>
						
						<div className="redHeart" >	
						</div>
						<h4>{this.state.gemBalance[14]}</h4>
					</div>				
				</div>
				<div className="inline">
					<div className="gemCardGreen" onClick={() => {this.setState({selectedGem: "greenDiamond"}); this.addMyGem("greenDiamond", 15)}}>
						<h3> Diamond </h3>
						<h4> 16 point </h4>	
						<div className="greenDiamond" >
						</div>
						<h4>{this.state.gemBalance[15]}</h4>
					</div>	
						<div className="gemCardTeal" onClick={() => {this.setState({selectedGem: "tealDiamond"}); this.addMyGem("tealDiamond", 16)}}>	
						<h3> Diamond  </h3>
						<h4>  32 point </h4>					
						<div className="tealDiamond" >
						</div>
						<h4>{this.state.gemBalance[16]}</h4>
					</div>			
					<div className="gemCardBlue" onClick={() => {this.setState({selectedGem: "blueDiamond"}); this.addMyGem("blueDiamond", 17)}}>
						<h3> Diamond  </h3>
						<h4>  64 point</h4>					
						<div className="blueDiamond" >	
						</div>	
						<h4>{this.state.gemBalance[17]}</h4>
					</div>	
					<div className="gemCardPurple" onClick={() => {this.setState({selectedGem: "purpleDiamond"}); this.addMyGem("purpleDiamond", 18)}}>	
						<h3> Diamond  </h3>
						<h4>  128 point</h4>					
						<div className="purpleDiamond" >
						</div>
						<h4>{this.state.gemBalance[18]}</h4>
					</div>					
					<div className="gemCardRed" onClick={() => {this.setState({selectedGem: "redDiamond"}); this.addMyGem("redDiamond", 19)}}>	
						<h3> Diamond  </h3>
						<h4> 256 point </h4>					
						<div className="redDiamond" >	
						</div>
						<h4>{this.state.gemBalance[19]}</h4>
					</div>				
				</div>			
			</div>
			<div>

				<div>
				<div className="columnDesktop">
					<div className="gemButtons">
						<div className="gemSpacerMobile"></div>
						
							<div>
								<div className="buttonContainer"   onClick={() => {this.showModalAllGemValues(this.state.account, this.state.balance, this.state.networkId)} } ><h4>All Gem values</h4></div>
								<div className="buttonContainer"   onClick={() => {this.showModalGemRoundValues(this.state.account, this.state.balance, this.state.networkId)} } ><h4>Gem trades</h4></div>
								<div className="buttonContainer"   onClick={() => {this.showModalGemDropValues(this.state.account, this.state.balance, this.state.networkId)} } ><h4>TearDrop trades</h4></div>
								<div className="buttonContainer"   onClick={() => {this.showModalGemHeartValues(this.state.account, this.state.balance, this.state.networkId)} } ><h4>Heart trades</h4></div>
								<div className="buttonContainer"   onClick={() => {this.showModalGemDiamondValues(this.state.account, this.state.balance, this.state.networkId)} } ><h4>Diamond trades</h4></div>
							</div>	
					
					</div>
					<div className="gemButtons">
						<div>	
							<div className="gemSpacerMobile"></div>
							<div><h4>Debug</h4></div>
							<div className="buttonContainer"   onClick={() => {this.freebie(0,3)} } ><h4>Get Freebies</h4></div>
							<div className="buttonContainer"   onClick={() => {this.spendGem(2)} } ><h4>Give Back 1</h4></div>
						</div>
					</div>
					<div className="gemButtons">
						<div>					
							<div className="gemSpacerMobile"></div>		
							{ approval }
						</div>	
					</div>
					</div>
				</div>
			</div>
			<div>
		
				<div className="theTrade">
					<div>
						<div className="inlineTight"> 
							{ gemTrade1 }  
							{ gemTrade2 }  
							<div className="gemSpacer"></div>
							{ gemTrade3 } 
						</div>
						<div className="inlineText"> 
							<h3> Gem 1 </h3>
							<h3> Gem 2 </h3>
							<div className="buttonContainer"   onClick={() => {this.addThirdGem()}} >
								<h4>Optional Gem 3</h4>
							</div> 
						</div>
					</div>
					<div className="tradeValue"><h3>trade for &nbsp;&nbsp;&nbsp;&nbsp;</h3></div>
					<div> { gemTradeResult }</div>
					
					<div className="tradeValue"><h3> Trade value: </h3><h2>{tradeValue}</h2></div>
					<div className="gemSpacer"></div>
					
					<div className="inline">
						<div><h4>Reset Trade</h4></div>
						<div className="buttonContainer"   onClick={() => {this.resetChoices()} } ><h4>ResetChoices</h4></div>	
					</div>
					<div className="gemSpacer"></div>
					<h3>You may have some gems to claim</h3>
					<div className="center"><div className="buttonContainer"   onClick={() => {this.checkClaim()} } ><h4>Check</h4></div></div>
					<h3> claimable = { this.state.gemsClaimable }</h3>
					<div className="center">{areGemsClaimable}</div>
				</div>	
			</div>
		</div>	
		</div>
	</ModalGems>
			<ModalAllGemValues  show18 = {this.state.show18}  handleClose18 = {this.hideModalAllGemValues}>

			</ModalAllGemValues>
			
			<ModalGemRoundValues  show13 = {this.state.show13}  handleClose13 = {this.hideModalGemRoundValues}>

			</ModalGemRoundValues>
			<ModalGemDropValues  show14 = {this.state.show14}  handleClose14 = {this.hideModalGemDropValues}>

			</ModalGemDropValues>			
			<ModalGemHeartValues  show15 = {this.state.show15}  handleClose15 = {this.hideModalGemHeartValues}>

			</ModalGemHeartValues>	
			<ModalGemDiamondValues  show16 = {this.state.show16}  handleClose16 = {this.hideModalGemDiamondValues}>

			</ModalGemDiamondValues>

<ModalWinner show17 = {this.state.show17}  handleClose17 = {this.hideModalWinner} id = {Number.parseInt(this.state.id) + 1} name = {this.state.name} raceNumber = {this.state.raceNumber}>
				
						
							<div className="MyAlfHeader">
								<div className="MyAlfContainer">	
								<div className="alienImage">
									<img  src={"https://tarnft.lets3d.com/hidden.jpg"} alt="My Alien"/>
								</div>
									<div className="inline">
										<h2>Name: { this.state.name }</h2>
									
										<h2>Id: { Number.parseInt(this.state.id) + 1 }</h2>
									</div>
										
								</div>		
								<div className="inline">
									<div className="StatsModalWinnerContainer">	
										<h1>Stats: </h1>
										<h2>___________</h2>
										<h2> Prestige: {(this.state.races*1)+(this.state.finishes*1)+(this.state.wins*2)+5}</h2>
										<h2>Level: { Number(this.state.races) + Number(this.state.finishes) }</h2>
										<h2>Starts: { this.state.races }</h2>
										<h2>Finishes: { this.state.finishes }</h2>
										<h2>Wins: { this.state.wins }</h2>
										<h2>Recent Time: { this.state.recentTime / 1000} </h2>
										<h2>Cooldown: { this.calculateTime(this.state.myCooldown) } hrs</h2>
										<h3> Owner: { this.state.allOwnersArray[this.state.id]}</h3>
										
										<div className="buttonContainer" onClick= {() => {this.getName(this.state.allOwnersArray[this.state.id])}} >
											<h4>Owner Name</h4>
										</div>	
										<h2>{this.state.gotName}</h2>
										
										<h3>{ this.showChamps(this.state.id) }</h3>
										<h2>___________</h2>
									</div>

									
								</div>
							</div>					
								
				</ModalWinner>
				
			</styles.StyledContainer> 
		)
	}
}

export default App; 