import { Button} from 'semantic-ui-react';
import './modal.css';
import './style.css';
import './gemStyle.css';

const ModalGemDropValues = ({ handleClose14, show14, children, user, balance }) => {
  const showHideClassName = show14 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose14()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>BACK</h3>
						</div>
					</div>

					<h2>GEM TEARDROP COLLECTION VALUES</h2>
					<div className="alfContainer gemContainer">	
						<div>
							<div className="inlineDesktop">
								<div className="inline">
									<div>
										<h3>Teardrop Gems</h3>
										<div className="dropGems">
										</div>
									</div>
									<div className="dropValues">
										<h4>value</h4>											
										<h3>8</h3>						
										<h3>16</h3>							
										<h3>16</h3>						
										<h3>32</h3>							
										<h3>32</h3>						
										<h3>64</h3>							
										<h3>64</h3>
										<h3>128</h3>
										<h3>128</h3>
								
									</div>
								</div>
								<div className="textValuesDrop">
									Teardrops
									<h4>2 x green = 8 = purpleGem or tealDrop or greenHeart</h4>
									<h4>3 x green = 16 = redGem or blueDrop or tealHeart or greenDiamond</h4>
									<h4>2 x teal = 16 = redGem or blueDrop or  tealHeart or greenDiamond</h4>
									<h4>3 x teal = 32 = purpleDrop or  blueHeart or tealDiamond</h4>
									<h4>2 x blue = 32 = purpleDrop or blueHeart or tealDiamond</h4>
									<h4>3 x blue = 64 = redDrop or purpleHeart or blueDiamond</h4>
									<h4>2 x purple = 64 =  redDrop or purpleHeart or blueDiamond</h4>
									<h4>3 x purple = 128 =  redHeart or purpleDiamond</h4>
									<h4>2 x red = 128 = 1 redHeart or purpleDiamond</h4>
		
								</div>

							</div>	
						</div>	
					</div>
				</div>
			</div>	
		</div>
	);	
};

export default ModalGemDropValues;