import { Button} from 'semantic-ui-react';
import './modal.css';
import './style.css';
import './gemStyle.css';

const ModalGemDiamondValues = ({ handleClose16, show16, children, user, balance }) => {
  const showHideClassName = show16 ? "modal display-block" : "modal display-none";

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer registerContainer">
					<div onClick= {() => {handleClose16()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h3>BACK</h3>
						</div>
					</div>
					<h2>GEM DIAMOND COLLECTION VALUES</h2>
					<div className="alfContainer gemContainer">	
									
						<div>
							<div className="inlineDesktop">
								<div className="inline">
									<div>
										<h3>Diamond Gems</h3>
										<div className="diamondGems">
										</div>
									</div>
									<div className="gemValues">
										<h4>value</h4>											
										<h3>32</h3>
										<h3>64</h3>
										<h3>64</h3>
										<h3>128</h3>
										<h3>128</h3>	
										<h3>256</h3>								
									</div>
								</div>
								<div className="textValuesDiamond">
									Diamonds
									<h4>2 x green = 32 = 1 x purpleDrop or blueHeart or tealDiamond</h4>
									<h4>3 x green = 64 = 1 x redDrop or purpleHeart or blueDiamond</h4>
									<h4>2 x teal = 64 = 1 x redDrop or purpleHeart or blueDiamond</h4>
									<h4>3 x teal = 128 = 1 x redHeart or purpleDiamond</h4>
									<h4>2 x blue = 128 = 1 x redHeart or purpleDiamond</h4>
									<h4>2 x purple = 256 = 1 x redDiamond</h4>
								</div>							
							</div>	
						</div>	
					</div>
				</div>
			</div>	
		</div>
	);	
};

export default ModalGemDiamondValues;