import './modal.css';
import './style.css';

const ModalPlot = ({ handleClose4, show4, children, race }, props) => {
  const showHideClassName = show4 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">	
				<div className="userContainer modalPlotContainer">
					<div onClick= {() => {handleClose4()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer closeButton">
							<h4>CLOSE</h4>
						</div>
					</div>
						<h2>PLOT COURSE   Race no.{ race }</h2>
					<div className="alfContainer modalPlotContainer">			
						{children}
						{props[0]}
					</div>	
				</div>
			</div>	
		</div>
	);	
};

export default ModalPlot;