import './modal.css';
import './style.css';

const ModalWhatsThis = ({ handleClose9, show9, children, user }) => {
  const showHideClassName = show9 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);

	return (
		<div className={showHideClassName}>
			<div className="headerContainer">		
				<div className="userContainer">
					<div onClick= {() => {handleClose9()}}style={{cursor:'pointer'}} >
						<div className="buttonContainer">
							<h3>CLOSE</h3>
						</div>
					</div>
					<h2>Final Fuel Explanation</h2>
					<h3>You have input engine strategies for all 9 legs of the journey.</h3>
					<h3><i>You will have some fuel left. </i></h3>
					<p></p>
					<h3>You can use a percentage of it for Warp engines. </h3>
					<p></p>
					<h3>If you use too much then there wont be enough left for the impulse engines and you will drift for part of the way home. </h3>
					<p>Suggestion: use less than 100% </p>
					<h3>Input a value between 1 - 100 and click </h3>
					<h3>"submit"</h3>
					<div className="finalFuelContainer">		
						{children}
					</div>	
					<p></p>
					<p>Check the Wiki for more detailed information</p>
				</div>
			</div>	
		</div>
	);	
};
export default ModalWhatsThis;