import './modal.css';
import './style.css';

const ModalLeaderboard = ({ handleClose7, show7, children, circ }, props) => {
  const showHideClassName = show7 ? "modal display-block" : "modal display-none";
//  const myData = props.myData;
 // console.log(props);
	return (
		<div className={showHideClassName}>
			<div className="headerContainerLeaderboard">	
				<div className="userContainer leaderboardContainer">
					<div className="inline">
						<div className="trophy">
							<img  src={"https://fusible.link/aliens/trophy.jpg"} width="50%" />
						</div>
						<h2>Leaderboard</h2>
						<h3> Total Aliens in Circulation:&nbsp;&nbsp;{ circ }</h3>
						<div onClick= {() => {handleClose7()}}style={{cursor:'pointer'}} >
							<div className="buttonContainer closeButton">
								<h3>CLOSE</h3>
							</div>
						</div>
						<div className="trophy">
							<img  src={"https://fusible.link/aliens/trophy.jpg"} width="50%" />
						</div>							
					</div>
					<div className="alfContainer leaderAlfContainer">			
						{children}
						{props[0]}
					</div>	
				</div>
			</div>
		</div>
	);	
};
export default ModalLeaderboard;